(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingJournalController', AccountingJournalController);

    AccountingJournalController.$inject = ['$scope', 'globalCompany', '$rootScope', '$state', 'AccountingJournal', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'AccountingAccount', 'House', 'AccountingSeat', 'Modal'];

    function AccountingJournalController($scope, globalCompany, $rootScope, $state, AccountingJournal, ParseLinks, AlertService, paginationConstants, pagingParams, AccountingAccount, House, AccountingSeat, Modal) {

        var vm = this;
        $rootScope.mainTitle = 'Libro diario';
        $rootScope.active = 'accountingJournal';
        vm.clientId = -1;
        vm.accountId = -1;
        vm.consulted = false;
        var date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };
        vm.loadAll = loadAll;

        vm.searchTerm = '';
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        };
        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };

        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        };

        function loadAllClients() {
            House.query({
                page: pagingParams.page - 1,
                size: 3000,
                companyId: globalCompany.getId()
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.clients = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAllDetailAccountingDetail();

        loadAllClients();

        vm.exportingExcel = false;
        vm.downloadExcel = function (table) {
            vm.notExportingExcel = false;
            vm.exportingExcel = true;
            setTimeout(function () {
                setTimeout(function () {
                    $scope.$apply(function () {
                        var uri = 'data:application/vnd.ms-excel;base64,'
                            ,
                            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                            , base64 = function (s) {
                                return window.btoa(unescape(encodeURIComponent(s)));
                            }
                            , format = function (s, c) {
                                return s.replace(/{(\w+)}/g, function (m, p) {
                                    return c[p];
                                });
                            };
                        var workSheetName = "Libro Diario del " + moment(vm.dates.initial_time).format("L") + " al " + moment(vm.dates.final_time).format("L");
                        if (!table.nodeType) table = document.getElementById(table);
                        var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML};
                        var a = document.createElement('a');
                        a.href = uri + base64(format(template, ctx));
                        a.download = workSheetName + '.xls';
                        //triggering the function
                        a.click();
                        vm.exportingExcel = false;
                    }, 1);
                    vm.notExportingExcel = true;
                }, 500);
            });
        };


        vm.deleteManualSeat = function (seat) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el asiento manual?", "Una vez eliminado no podrá recuperarse.", function () {
                AccountingSeat.delete({id: seat.id}, function (result) {
                    Modal.toast("Eliminado correctamente");
                    loadAll();
                });
            });
        };

        function loadAllDetailAccountingDetail() {
            AccountingAccount.findDetailAccountsByCompanyId({
                companyId: globalCompany.getId(),
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.accountingDetailAccounts = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }


        // loadAll();

        function loadAll() {
            vm.isReady = false;
            vm.consulted = true;
            AccountingJournal.getByCompanyIdAndBetweenDates({
                companyId: globalCompany.getId(),
                initialTime: moment(vm.dates.initial_time).format(),
                finalTime: moment(vm.dates.final_time).format(),
                clientId: vm.clientId,
                accountId: vm.accountId,
            }, onSuccess, onError);


            function onSuccess(data, headers) {
                vm.accountingJournal = data;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
    }
})();
