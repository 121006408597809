(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('EgressDeleteController', EgressDeleteController);

    EgressDeleteController.$inject = ['Modal', 'CommonMethods', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CreditNote', 'Egress', 'House', 'Company', 'AccountingPeriod', 'globalCompany'];

    function EgressDeleteController(Modal, CommonMethods, $timeout, $scope, $stateParams, $uibModalInstance, entity, CreditNote, Egress, House, Company, AccountingPeriod, globalCompany) {
        var vm = this;
        vm.egress = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.saving = false;
        vm.today = new Date();
        var id = CommonMethods.decryptIdUrl($stateParams.egressId);
        vm.disableInput = true;
        vm.notFound = true;

        Egress.get({id: id}, function (result) {
            vm.egress = result;
            vm.egressDeleteDate = vm.egress.date;
            AccountingPeriod.getLastPeriodCloseDate({companyId: globalCompany.getId()}, function (result) {
                var minDate = new Date(result.date);
                vm.notFound = false;
                vm.closePeriodDate = minDate;
                var lastDayClose = new Date(minDate.getFullYear(), minDate.getMonth() + 1, 1);
                $timeout(function(){
                    $scope.$apply(function () {
                        vm.minDate = lastDayClose;
                        if(lastDayClose<vm.egress.date){
                            vm.movementDate = vm.egress.date;
                        }else{
                            vm.movementDate = lastDayClose;
                            vm.disableInput = false;
                        }
                    });
                });
            },function(notFound){
                vm.notFound = true;
                vm.movementDate = vm.egress.date;
            });
        });

        vm.deleteExpense = function(){
            Modal.confirmDialog("¿Está seguro que desea eliminar la cuenta por pagar?", "La fecha del asiento de anulación será la seleccionada y se eliminarán todos los abonos realizados en caso de tener alguno",function(){
                Egress.deleteEgress({id:id,deletionDate:moment(vm.movementDate).format(),isClosePeriodDate:vm.disableInput},function(){
                    Modal.toast("Eliminado correctamente");
                    $uibModalInstance.close();
                });
            });
        };

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }


        function onSaveSuccess(result) {
            $scope.$emit('aditumApp:creditNoteUpdate', result);
            $uibModalInstance.close(result);
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.originalBillDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
