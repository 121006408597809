
(function () {
    'use strict';

    angular
            .module('aditumApp')
            .controller('CommonAreaReservationsDialogResidentViewController', CommonAreaReservationsDialogResidentViewController);

    CommonAreaReservationsDialogResidentViewController.$inject = ['$translate','BlockCommonArea', 'BlockReservation', 'AditumStorageService', 'PaymentProof', '$timeout', '$scope', '$stateParams', 'entity', 'CommonAreaReservations', 'CommonArea', '$rootScope', 'House', 'Resident', 'CommonAreaSchedule', 'AlertService', '$state', 'CommonMethods', 'Principal', 'Modal', 'CompanyConfiguration', 'globalCompany'];

    function CommonAreaReservationsDialogResidentViewController(translate, BlockCommonArea, BlockReservation, AditumStorageService, PaymentProof, $timeout, $scope, $stateParams, entity, CommonAreaReservations, CommonArea, $rootScope, House, Resident, CommonAreaSchedule, AlertService, $state, CommonMethods, Principal, Modal, CompanyConfiguration, globalCompany) {
        var vm = this;
        vm.isAuthenticated = Principal.isAuthenticated;
        vm.commonarea = {};
        $rootScope.active = "reservationDialogResidentView";
        vm.commonArea = entity;
        moment.locale($rootScope.currentLang);
        var initialDateTemporal;
        vm.companyCurrent = globalCompany.getId();
        vm.datePickerOpenStatus = {};
        vm.today = CommonMethods.setTimezone(new Date());
        vm.conditionsAcepted = false;
        vm.companyFilt = globalCompany.getId();
        vm.openCalendar = openCalendar;
        vm.reservationTitle = "Crear";
        $rootScope.mainTitle = translate.instant("aditumApp.commonAreaReservations.form.title");
        vm.isMorosa = false;
        vm.fileName = undefined;
        vm.company = $rootScope.company;
        vm.confirmMessage = confirmMessage;
        Modal.enteringForm(confirmMessage);
        $scope.$on("$destroy", function () {
            Modal.leavingForm();
        });

        vm.notAllowPaymentProof = function () {
            return globalCompany.getId() == 1 && vm.fileName == undefined && vm.commonarea.chargeRequired == 1;
        };

        vm.minimunDate = CommonMethods.setTimezone(new Date());

        vm.isToday = function (date) {
            var today = CommonMethods.setTimezone(new Date());
            return today.getDate() == date.getDate() && today.getMonth() == date.getMonth() && today.getFullYear() == date.getFullYear();
        };

        vm.disabledMinimalHour = function (hour, initialTime) {
            if (hour != undefined && initialTime != undefined) {
                return hour.value <= initialTime.value;
            } else {
                return false;
            }
        };
        vm.disabledMinimalDay = function (hour) {
            var currentHour = CommonMethods.setTimezone(new Date());
            if (vm.isToday(vm.commonAreaReservations.initalDate)) {
                if (vm.commonarea.hasBlocks == 0) {
                    return hour.value <= currentHour.getHours() - 1;
                } else {
                    return parseFloat(hour.initialValue) <= currentHour.getHours() - 1 && parseFloat(hour.finalValue) <= currentHour.getHours();
                }
            }
            return false;
        };

        vm.required = 1;
        vm.hours = [];
        vm.isReady = false;
        vm.schedule = [];
        vm.allDaySchedule = 1;
        vm.scheduleIsAvailable = false;

        vm.diasDeLaSemanaES = ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', ''];
        vm.mesesDelAnnoES = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Setiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        vm.diasDeLaSemanaEN = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', ''];
        vm.mesesDelAnnoEN = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        vm.timeSelected = {};

        if($rootScope.currentLang=="es"){
            vm.diasDeLaSemana = vm.diasDeLaSemanaES;
            vm.mesesDelAnno = vm.mesesDelAnnoES;
        }else{
            vm.diasDeLaSemana = vm.diasDeLaSemanaEN;
            vm.mesesDelAnno = vm.mesesDelAnnoEN;
        }
        vm.dateNotPermited = false;
        vm.commonarea.devolutionAmmount = 0;
        vm.commonAreaReservations = {peopleQuantity: 0};

        setTimeout(function () {
            CommonArea.query({
                size: 100,
                companyId: globalCompany.getId()
            }, onSuccessCommonAreas, onError);
        }, 1500);

        CompanyConfiguration.get({id: globalCompany.getId()}, function (data) {
            if (data.hasContability == 1) {
                vm.hasContability = true;
            } else {
                vm.hasContability = false;
            }
        });


        var file = null;
        vm.viewPreview = function () {
            $state.go("common-area-reservation-resident-view.record-file-preview", {url: vm.commonarea.conditionsToAccept});
        };

        function upload() {
            vm.isSaving = true;
            var today = new Date();
            moment.locale("es");
            vm.direction = globalCompany.getId() + '/payment-proof/' + moment(today).format("YYYY") + '/' + moment(today).format("MMMM") + '/' + globalCompany.getHouseId() + '/';
            var uploadTask = AditumStorageService.ref().child(vm.direction + file.name).put(file);
            uploadTask.on('state_changed', function (snapshot) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    });
                }, 1);
                switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
            }, function (error) {
                    // Handle unsuccessful uploads
            }, function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
                    vm.paymentProof = {};
                    vm.paymentProof.imageUrl = downloadURL;
                    vm.paymentProof.houseId = globalCompany.getHouseId();
                    vm.paymentProof.status = 1;
                    vm.paymentProof.companyId = globalCompany.getId();
                    vm.paymentProof.registerDate = moment(new Date());
                    vm.paymentProof.subject = translate.instant("aditumApp.commonAreaReservations.form.paymentReceiptForReservation") + vm.commonarea.name + ".";
                    vm.commonAreaReservations.initalDate.setHours(0);
                    vm.commonAreaReservations.initalDate.setMinutes(0);
                    vm.paymentProof.description = translate.instant("aditumApp.commonAreaReservations.form.reservationFeePayment") + vm.commonarea.name + translate.instant("aditumApp.commonAreaReservations.form.forDate") + moment(vm.commonAreaReservations.initalDate).format("MM/DD/YYYY") + ".";
                    PaymentProof.save(vm.paymentProof, onSaveSuccessPayment, onSaveError);
                });
            });
        }

        function onSaveSuccessPayment(data) {
            createReservation(data.id);
        }


        function onSuccessCommonAreas(data) {
            vm.commonareas = data;
            vm.isReady = true;
            loadInfo();
        }

        function loadInfo() {
            vm.residentsByHouse();
            if (entity.id !== null) {
                vm.commonarea.id = entity.id;
                vm.loadSchedule();
                if ($state.params.date !== undefined) {
                    vm.commonAreaReservations.initalDate = new Date($state.params.date);
                }
            }
        }

        vm.changeAreaLoad = function () {
            vm.commonAreaReservations.initalDate = undefined;
            vm.loadSchedule();
        };

        vm.loadSchedule = function () {
            vm.scheduleIsAvailable = false;
            vm.scheduleNotAvailable = false;
            vm.maxDate = undefined;
            vm.isMorosa = false;
            vm.hours = [];
            BlockReservation.isBlocked({houseId: globalCompany.getHouseId()}, function (data) {
                data.blocked = data.blocked == 1;
                vm.blockReservation = data;
            });

            BlockCommonArea.getByCommonArea({id: vm.commonarea.id}, function (data) {
                vm.blockingHours = data;
            }, function () {

            });


            CommonArea.get({
                id: vm.commonarea.id
            }, function (result) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.commonarea = result;
                        if (vm.commonarea.reservationWithDebt == 2) {
                            House.isDefaulterInCommonArea({
                                id: globalCompany.getHouseId(),
                                commonAreaId: vm.commonarea.id
                            }, function (result) {
                                vm.isMorosa = result.due == "1";
                                if (vm.isMorosa) {
                                    Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.settleFeesToUseAmenity")); //"Cancele sus cuotas para poder utilizar la amenidad.")
                                }
                            });
                        }
                    });
                });


                if (vm.commonarea.hasDefinePeopleQuantity) {
                    vm.guessGuantity = [];
                    for (var i = 0; i <= vm.commonarea.quantityGuestLimit; i++) {
                        vm.guessGuantity.push(i);
                    }
                }
                setTimeout(function () {
                    $scope.$apply(function () {
                        if (vm.commonarea.hasMaximunDaysInAdvance) {
                            vm.maxDate = moment(CommonMethods.setTimezone(new Date())).add(vm.commonarea.maximunDaysInAdvance, 'days').toDate();
                        }
                    });
                }, 200);
                $("#scheduleDiv").fadeOut(50);
                $("#loadingSchedule").fadeIn('0');
                if (vm.commonarea === undefined) {
                    $("#loadingSchedule").fadeOut('20');
                } else {
                    CommonAreaSchedule.findSchedulesByCommonArea({
                        commonAreaId: result.id
                    }, onSuccessSchedule, onErrorSchedule);
                }
            });
        };

        function onSuccessSchedule(data, headers) {
            vm.schedule = [];
            setTimeout(function () {
                $scope.$apply(function () {
                    if (vm.commonarea.hasMaximunDaysInAdvance) {
                        vm.maxDate = moment(new Date()).add(vm.commonarea.maximunDaysInAdvance, 'days').toDate();
                    }
                    if (data[0].lunes !== "-") {
                        formatScheduleTime("Lunes", data[0].lunes, 1);
                    }
                    if (data[0].martes !== "-") {
                        formatScheduleTime("Martes", data[0].martes, 2);
                    }
                    if (data[0].miercoles !== "-") {
                        formatScheduleTime("Miércoles", data[0].miercoles, 3);
                    }
                    if (data[0].jueves !== "-") {
                        formatScheduleTime("Jueves", data[0].jueves, 4);
                    }
                    if (data[0].viernes !== "-") {
                        formatScheduleTime("Viernes", data[0].viernes, 5);
                    }
                    if (data[0].sabado !== "-") {

                        formatScheduleTime("Sábado", data[0].sabado, 6);
                    }
                    if (data[0].domingo !== "-") {
                        formatScheduleTime("Domingo", data[0].domingo, 0);
                    }
                    if (vm.commonarea.maximunHours == 0) {
                        vm.maximunHoursTitle = translate.instant("aditumApp.commonAreaReservations.form.usageTime"); //"Tiempo de uso:";
                        vm.maximunHours = " Bloque completo";
                        vm.allDaySchedule = 2;
                    } else {
                        vm.maximunHoursTitle = translate.instant("aditumApp.commonAreaReservations.form.usageMaxTime"); //"Tiempo máximo de uso: ";
                        vm.maximunHours = vm.commonarea.maximunHours + translate.instant("aditumApp.commonAreaReservations.form.hour");
                        vm.allDaySchedule = 1;
                    }
                    $("#scheduleDiv").fadeIn('50');
                    $("#loadingSchedule").fadeOut('20');
                    $("#commonAreaImage").fadeIn('300');
                    if (vm.commonAreaReservations.id != null || $state.params.commonAreaId !== undefined || $state.params.date !== undefined) {
                        if (isTheDayInSchedule(vm.commonAreaReservations.initalDate.getDay())) {
                            vm.commonAreaReservations.initalDate.setHours(0);
                            vm.commonAreaReservations.initalDate.setMinutes(0);
                            if (vm.commonarea.maximunHours === 0) {
                                $("#loadingAvailability").fadeIn('50');
                                var initialTime = "0";
                                var finalTime = "0";
                                if (vm.commonAreaReservations.id != null || vm.commonAreaReservations.id != undefined) {
                                    CommonAreaReservations.isAvailableToReserveNotNull({
                                        maximun_hours: vm.commonarea.maximunHours,
                                        reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                                        initial_time: initialTime,
                                        final_time: finalTime,
                                        common_area_id: vm.commonarea.id,
                                        house_id: globalCompany.houseId(),
                                        reservation_id: vm.commonAreaReservations.id
                                    }, onSuccessIsAvailable, onError);
                                } else {
                                    CommonAreaReservations.isAvailableToReserve({
                                        maximun_hours: vm.commonarea.maximunHours,
                                        reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                                        initial_time: initialTime,
                                        final_time: finalTime,
                                        house_id: globalCompany.houseId(),
                                        common_area_id: vm.commonarea.id

                                    }, onSuccessIsAvailable, onError);
                                }
                            } else {
                                addHoursToSelect();
                            }
                        } else {
                            $("#loadingAvailability").fadeOut('50');
                            vm.dateNotPermited = true;
                            Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.reservationNotAllowedDay") + vm.diasDeLaSemana[vm.commonAreaReservations.initalDate.getDay()] + translate.instant("aditumApp.commonAreaReservations.form.inThisCommonArea"));
                        }
                    }
                });
            }, 0);
        }

        function revalidate() {
            var initialTime = "0";
            var finalTime = "0";
            initialTime = vm.timeSelected.initialTime.value;
            finalTime = vm.timeSelected.finalTime.value;
            CommonAreaReservations.isAvailableToReserve({
                maximun_hours: vm.commonarea.maximunHours,
                reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                initial_time: initialTime,
                final_time: finalTime,
                common_area_id: vm.commonarea.id,
                house_id: globalCompany.getHouseId(),
            }, function (data) {
                vm.availability = data.availability;
                show(data.availability);
                if (data.availability == 0) {
                    vm.scheduleIsAvailable = true;

                }
            }, onError);
        }

        function esEntero(numero) {
            if (numero % 1 == 0) {
                return true;
            } else {
                return false;
            }
        }

        function formatHourToDisplayAM(hour) {
            var twelve = Math.round(hour);
            var hourC = (hour - Math.round(hour)).toFixed(2);
            var result = "";
            var sum = (twelve == 0 || twelve == 1) && hour > 13 ? 12 : 0;
            var total = parseFloat(hour.split(".")[0]) + sum;
            if ((hourC == 0.15)) {
                result = total + ":15AM";
            }
            if ((hourC == 0.5 || hourC == -0.5)) {
                result = total + ":30AM";
            }
            if ((hourC == 0.75 || hourC == -0.75)) {
                result = total + ":45AM";
            }
            if ((hourC == 0.25 || hourC == -0.25)) {
                result = total + ":45AM";
            }
            if ((hourC == 0.45 || hourC == -0.45)) {
                result = total + ":45AM";
            }
            return result;
        }

        function formatHourToDisplayPM(hour) {
            var twelve = Math.round(hour);
            var hourC = (Math.round(hour) - hour).toFixed(2);
            var result = "";
            var rest = (twelve == 13 || twelve == 12) && hour < 13 ? 0 : 12;
            var total = parseFloat(hour.split(".")[0]) - rest;
            if ((hourC == 0.75 || hourC == -0.75)) {
                result = total + ":15PM";
            }
            if ((hourC == 0.15 || hourC == -0.15)) {
                result = total + ":15PM";
            }
            if ((hourC == 0.5 || hourC == -0.5)) {
                result = total + ":30PM";
            }
            if ((hourC == 0.25 || hourC == -0.25)) {
                result = total + ":45PM";
            }
            if ((hourC == 0.45 || hourC == -0.45)) {
                result = total + ":45PM";
            }
            return result;
        }


        function formatScheduleTime(day, time, number) {
            var item = {};
            item.day = day;
            item.numberDay = number;
            if (vm.commonarea.hasBlocks == 0) {
                var times = time.split("-");
                item.initialValue = times[0];
                item.finalValue = times[1];
                if (Math.round(times[0]) > 12) {
                    if (esEntero(parseFloat(times[0]))) {
                        item.initialTime = parseFloat(times[0]) - 12 + ":00PM";
                    } else {
                        item.initialTime = formatHourToDisplayPM(times[0]);
                    }
                } else {
                    vm.twelve = Math.round(times[0]);
                    if (vm.twelve == 12) {
                        if (esEntero(parseFloat(times[0]))) {
                            item.initialTime = "12:00PM";
                        } else {
                            item.initialTime = formatHourToDisplayPM(times[0]);
                        }
                    } else {
                        if (esEntero(parseFloat(times[0]))) {
                            if (times[0] == 0) {
                                item.initialTime = "12:00AM";
                            } else {
                                item.initialTime = parseFloat(times[0]) + ":00AM";
                            }
                        } else {
                            item.initialTime = formatHourToDisplayAM(times[0]);
                        }
                    }
                }
                if (Math.round(times[1]) > 12) {
                    if (esEntero(parseFloat(times[1]))) {
                        item.finalTime = parseFloat(times[1]) - 12 + ":00PM";
                    } else {
                        item.finalTime = formatHourToDisplayPM(times[1]);
                    }
                } else {
                    if (esEntero(parseFloat(times[1]))) {
                        item.finalTime = parseFloat(times[1]) + ":00AM";
                    } else {
                        item.finalTime = formatHourToDisplayAM(times[1]);
                    }
                }
                item.time = item.initialTime + " - " + item.finalTime;
                vm.schedule.push(item);
            } else {
                var allTimes = time.split(",");
                item.times = [];
                for (var i = 0; i < allTimes.length; i++) {
                    var times = allTimes[i].split("-");
                    var initialValue = times[0];
                    var finalValue = times[1];
                    if (Math.round(times[0]) > 12) {
                        if (esEntero(parseFloat(times[0]))) {
                            item.initialTime = parseFloat(times[0]) - 12 + ":00PM";
                        } else {
                            item.initialTime = formatHourToDisplayPM(times[0]);
                        }
                    } else {
                        var twelve = Math.round(times[0]);
                        if (twelve == 12) {
                            if (esEntero(parseFloat(times[0]))) {
                                item.initialTime = "12:00PM";
                            } else {
                                item.initialTime = formatHourToDisplayPM(times[0]);
                            }
                        } else {
                            if (esEntero(parseFloat(times[0]))) {
                                if (times[0] == 0) {
                                    item.initialTime = "12:00AM";
                                } else {
                                    item.initialTime = parseFloat(times[0]) + ":00AM";
                                }
                            } else {
                                item.initialTime = formatHourToDisplayAM(times[0]);
                            }
                        }
                    }
                    if (Math.round(times[1]) >= 12) {
                        if (times[1] > 12 && times[1] < 13) {
                            if (esEntero(parseFloat(times[1]))) {
                                item.finalTime = parseFloat(times[1]) + ":00PM";
                            } else {
                                item.finalTime = formatHourToDisplayPM(times[1]);
                            }
                        } else {
                            if (esEntero(parseFloat(times[1]))) {
                                if (parseFloat(times[1]) == 12) {
                                    item.finalTime = "12:00PM";
                                } else {
                                    item.finalTime = parseFloat(times[1]) - 12 + ":00PM";
                                }
                            } else {
                                item.finalTime = formatHourToDisplayPM(times[1]);
                            }
                        }
                    } else {
                        if (esEntero(parseFloat(times[1]))) {
                            if (times[1] == 0) {
                                item.finalTime = "12:00AM";
                            } else {
                                item.finalTime = parseFloat(times[1]) + ":00AM";
                            }
                        } else {
                            item.finalTime = formatHourToDisplayAM(times[1]);
                        }
                    }
                    item.times.push({
                        initialValue: initialValue,
                        finalValue: finalValue,
                        time: item.initialTime + " - " + item.finalTime
                    });
                }
                vm.schedule.push(item);
            }
        }

        function showErrorMessage(available) {
            vm.errorMessage = "";
            switch (available) {
            case 1 :
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationLimitReached2"); //"No es posible porque ha llegado al limite de reservaciones por periodo.";
                break;
            case 2 :
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationNoticeRequired") + vm.commonarea.daysBeforeToReserve + translate.instant("aditumApp.commonAreaReservations.form.daysInAdvance");
                break;
            case 3 :
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.minimumReservationGapRequired") + vm.commonarea.distanceBetweenReservations + translate.instant("aditumApp.commonAreaReservations.form.daysBetweenReservations");
                break;
            case 4:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationLimitReached") + vm.commonarea.limitActiveReservations + translate.instant("aditumApp.commonAreaReservations.form.activeReservationsInfo");
                break;
            case 5:
                var vezText = vm.commonarea.timesPerDay == 1 ? "vez" : "veces";
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationLimitExceeded") + vm.commonarea.timesPerDay + " " + vezText + translate.instant("aditumApp.commonAreaReservations.form.sameDay");
                break;
            case 6:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.selectedHoursBlocked"); //"Las horas seleccionadas se encuentran bloqueadas actualmente.";
                break;
            case 7:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationTooFarInAdvance"); //"No puedes reservar a tantos días en el futuro";
                Modal.toast(vm.errorMessage);
                break;
            case 10:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.selectedHoursOccupied2"); //"Las horas seleccionadas se encuentran ocupadas para reservar.";
                break;
            case 20:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.selectedHoursOccupiedCommonAreas"); //"Este área común bloquea otra al realizar la reserva y las horas seleccionadas se encuentran ocupadas para reservar en las áreas comunes relacionadas a esta área común.";
                break;
            default:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationConditionNotMet"); //"No es posible resevar porque no cumple alguna condición establecida.";
                break;
            }
        }

        function showErrorMessageToast(available) {
            vm.errorMessage = "";
            switch (available) {
            case 1 :
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationLimitReached2"); //"No es posible porque ha llegado al limite de reservaciones por periodo.";
                Modal.toast(vm.errorMessage);
                break;
            case 2 :
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationNoticeRequired") + vm.commonarea.daysBeforeToReserve + translate.instant("aditumApp.commonAreaReservations.form.daysInAdvance");
                Modal.toast(vm.errorMessage);

                break;
            case 3 :
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.minimumReservationGapRequired") + vm.commonarea.distanceBetweenReservations + translate.instant("aditumApp.commonAreaReservations.form.daysBetweenReservations");
                Modal.toast(vm.errorMessage);

                break;
            case 4:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationLimitReached") + vm.commonarea.limitActiveReservations + translate.instant("aditumApp.commonAreaReservations.form.activeReservationsInfo");
                Modal.toast(vm.errorMessage);

                break;
            case 5:
                var vezText = vm.commonarea.timesPerDay == 1 ? "vez" : "veces";
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationLimitExceeded") + vm.commonarea.timesPerDay + " " + vezText + translate.instant("aditumApp.commonAreaReservations.form.sameDay");
                Modal.toast(vm.errorMessage);

                break;
            case 7:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.reservationTooFarInAdvance"); //"No puedes reservar a tantos días en el futuro";
                Modal.toast(vm.errorMessage);
                break;
            case 10:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.selectedHoursOccupied2"); //"Las horas seleccionadas se encuentran ocupadas para reservar.";
                Modal.toast(vm.errorMessage);
                break;
            case 20:
                vm.errorMessage = translate.instant("aditumApp.commonAreaReservations.form.selectedHoursOccupiedCommonAreas"); //"Las horas seleccionadas se encuentran ocupadas para reservar en las áreas comunes relacionadas a esta área común.";
                Modal.toast(vm.errorMessage);
                break;
            }
        }

        vm.validateBlocksHours = function (hour, index) {
            vm.scheduleIsAvailable = false;
            vm.scheduleNotAvailable = false;
            vm.timeSelected.initialTime = parseFloat(hour.initialTime);
            vm.timeSelected.finalTime = parseFloat(hour.finalTime);
            vm.checkAvailabilityBlocks();
        };

        var temporalFinalTime;
        vm.validateDaysInitialHours = function (hour, index) {
            vm.scheduleIsAvailable = false;
            vm.scheduleNotAvailable = false;
            angular.forEach(vm.hours, function (item, index) {
                if (item.value == hour.value) {
                    if (vm.hours[index + vm.commonarea.maximunHours] == undefined) {
                        vm.timeSelected.finalTime = vm.hours[index + 1];
                    } else {
                        vm.timeSelected.finalTime = vm.hours[index + 1];
                    }
                    temporalFinalTime = vm.timeSelected.finalTime;
                }

            });
            vm.checkAvailabilityBetweenHours();
        };

        vm.validateDaysFinalHours = function (item) {
            vm.scheduleIsAvailable = false;
            vm.scheduleNotAvailable = false;
            if (vm.timeSelected.initialTime !== undefined && vm.timeSelected.finalTime !== undefined) {
                if (parseFloat(vm.timeSelected.initialTime.value) >= parseFloat(vm.timeSelected.finalTime.value)) {
                    vm.timeSelected.finalTime.isValid = false;
                    item.isValid = false;
                    Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.endTimeAfterStartTime")); //"Debe seleccionar una hora final posterior a la hora anterior");
                } else {
                    if (vm.timeSelected.finalTime.value - vm.timeSelected.initialTime.value > vm.commonarea.maximunHours) {
                        vm.timeSelected.finalTime.isValid = false;
                        item.isValid = false;
                        Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.exceedsMaximumHours")); //"No puede seleccionar más horas del máximo establecido");
                        vm.timeSelected.finalTime = vm.hours[index + 1];
                    } else {
                        vm.timeSelected.finalTime.isValid = false;
                        item.isValid = false;
                        $("#loadingAvailability").fadeIn('50');
                    }
                    vm.timeSelected.finalTime.isValid = true;
                    vm.checkAvailabilityBetweenHours();
                }
            }
        };

        vm.checkAvailabilityBetweenHours = function () {
            vm.commonAreaReservations.peopleQuantity = undefined;
            $("#loadingAvailability").fadeIn('0');
            if (parseFloat(vm.timeSelected.initialTime.value) == parseFloat(vm.timeSelected.finalTime.value)) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.timeSelected.finalTime.isValid = false;
                    });
                }, 100);
                Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.oneHourDifferenceRequired")); //"Debe seleccionar al menos una hora de diferencia");
            } else {
                if (vm.commonAreaReservations.id != null || vm.commonAreaReservations.id != undefined) {

                    vm.commonAreaReservations.initalDate.setHours(0);
                    vm.commonAreaReservations.initalDate.setMinutes(0);
                    CommonAreaReservations.isAvailableToReserveNotNull({
                        maximun_hours: vm.commonarea.maximunHours,
                        reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                        initial_time: vm.timeSelected.initialTime.value,
                        final_time: vm.timeSelected.finalTime.value,
                        common_area_id: vm.commonarea.id,
                        house_id: globalCompany.getHouseId(),
                        reservation_id: vm.commonAreaReservations.id
                    }, onSuccessIsAvailable, onError);
                } else {
                    var a = {
                        maximun_hours: vm.commonarea.maximunHours,
                        reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                        initial_time: vm.timeSelected.initialTime.value,
                        final_time: vm.timeSelected.finalTime.value,
                        common_area_id: vm.commonarea.id,
                        house_id: globalCompany.getHouseId(),
                    };
                    CommonAreaReservations.isAvailableToReserve(a, onSuccessIsAvailable, onError);
                }
            }
        };


        vm.checkAvailabilityBlocks = function () {
            vm.commonAreaReservations.peopleQuantity = undefined;
            $("#loadingAvailability").fadeIn('0');
            if (vm.commonAreaReservations.id != null) {
                vm.commonAreaReservations.initalDate.setHours(0);
                vm.commonAreaReservations.initalDate.setMinutes(0);
                CommonAreaReservations.isAvailableToReserveNotNull({
                    maximun_hours: vm.commonarea.maximunHours,
                    reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                    initial_time: vm.timeSelected.initialTime.value,
                    final_time: vm.timeSelected.finalTime.value,
                    common_area_id: vm.commonarea.id,
                    house_id: globalCompany.getHouseId(),
                    reservation_id: vm.commonAreaReservations.id
                }, onSuccessIsAvailable, onError);
            } else {
                CommonAreaReservations.isAvailableToReserve({
                    maximun_hours: vm.commonarea.maximunHours,
                    reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                    initial_time: vm.timeSelected.initialValue,
                    final_time: vm.timeSelected.finalValue,
                    common_area_id: vm.commonarea.id,
                    house_id: globalCompany.getHouseId(),
                }, onSuccessIsAvailable, onError);


            }

        };

        function isTheDayInSchedule(day) {
            var isContained = false;

            angular.forEach(vm.schedule, function (item, key) {

                if (item.numberDay == day) {
                    isContained = true;
                    vm.daySelected = item;
                }
            });
            if (isContained) {
                return true;
            } else {
                return false;
            }
        }

        vm.checkAvailability = function () {
            vm.dateNotPermited = false;
            vm.commonAreaReservations.peopleQuantity = undefined;
            vm.hours = [];
            vm.timeSelected = null;
            vm.scheduleIsAvailable = false;
            vm.scheduleNotAvailable = false;
            vm.commonAreaReservations.initalDate.setHours(0);
            vm.commonAreaReservations.initalDate.setMinutes(0);
            if (isTheDayInSchedule(vm.commonAreaReservations.initalDate.getDay())) {
                if (vm.commonarea.maximunHours === 0 && vm.commonarea.hasBlocks == 0) {
                    $("#loadingAvailability").fadeIn('50');
                    var initialTime = "0";
                    var finalTime = "0";
                    if (vm.commonarea.maximunHours !== 0) {
                        initialTime = vm.timeSelected.initialTime.value;
                        finalTime = vm.timeSelected.finalTime.value;
                    }
                    if (vm.commonAreaReservations.id != null) {
                        CommonAreaReservations.isAvailableToReserveNotNull({
                            maximun_hours: vm.commonarea.maximunHours,
                            reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                            initial_time: initialTime,
                            final_time: finalTime,
                            common_area_id: vm.commonarea.id,
                            house_id: globalCompany.getHouseId(),
                            reservation_id: vm.commonAreaReservations.id
                        }, onSuccessIsAvailable, onError);
                    } else {
                        CommonAreaReservations.isAvailableToReserve({
                            maximun_hours: vm.commonarea.maximunHours,
                            reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                            initial_time: initialTime,
                            final_time: finalTime,
                            common_area_id: vm.commonarea.id,
                            house_id: globalCompany.getHouseId(),
                        }, onSuccessIsAvailable, onError);
                    }


                } else if (vm.commonarea.maximunHours > 0 && vm.commonarea.hasBlocks == 0) {
                    addHoursToSelect();
                } else if (vm.commonarea.hasBlocks == 1) {
                    addBlocksToSelect();
                }
            } else {
                $("#loadingAvailability").fadeOut('50');
                vm.dateNotPermited = true;
                Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.reservationNotAllowed") + vm.diasDeLaSemana[vm.commonAreaReservations.initalDate.getDay()] + translate.instant("aditumApp.commonAreaReservations.form.inThisCommonArea"));
            }
        };
        vm.formatearNumero = function (nStr) {

            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? ',' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        };

        function onSuccessIsAvailable(data) {
            vm.dateNotPermited = false;
            $("#loadingAvailability").fadeOut('50');
            vm.availability = data.availability;
            showErrorMessage(data.availability);
            if (data.availability == 0) {
                vm.scheduleIsAvailable = true;
                vm.scheduleNotAvailable = false;
            } else {
                if (vm.commonAreaReservations.id != null || vm.commonAreaReservations.id != undefined) {
                    if (vm.commonarea.maximunHours == 0 && initialDateTemporal.getMonth() == vm.commonAreaReservations.initalDate.getMonth() && initialDateTemporal.getFullYear() == vm.commonAreaReservations.initalDate.getFullYear() && initialDateTemporal.getDate() == vm.commonAreaReservations.initalDate.getDate()) {
                        vm.scheduleIsAvailable = true;
                        vm.scheduleNotAvailable = false;

                    } else {

                        vm.scheduleIsAvailable = false;
                        vm.scheduleNotAvailable = true;
                    }
                } else {
                    vm.scheduleIsAvailable = false;
                    vm.scheduleNotAvailable = true;
                }
            }
            vm.spacesOcupated = data.peopleQuantity;
            if (vm.commonarea.hasDefinePeopleQuantity) {
                vm.spacesFree = [];
                if (vm.commonarea.hasMaxCapacity) {
                    vm.commonarea.maxCapacityN = vm.commonarea.maxCapacity;
                    vm.quantityfreeSpaces = 0;
                    if (vm.commonarea.maxCapacityN > vm.spacesOcupated) {
                        if (vm.commonarea.maxCapacityN - vm.spacesOcupated > vm.commonarea.quantityGuestLimit) {
                            vm.quantityfreeSpaces = vm.commonarea.quantityGuestLimit;
                        } else {
                            vm.quantityfreeSpaces = (vm.commonarea.maxCapacityN - vm.spacesOcupated);
                        }
                    }
                    vm.spacesFree = [];
                    for (var i = 1; i <= vm.quantityfreeSpaces; i++) {
                        var space = {value: i, description: "", disabled: false};
                        vm.spacesFree.push(space);
                    }
                    for (var i = vm.quantityfreeSpaces + 1; i <= vm.commonarea.quantityGuestLimit; i++) {
                        var space = {value: i, description: translate.instant("aditumApp.commonAreaReservations.form.maximumCapacityReached"), disabled: true};
                        vm.spacesFree.push(space); //
                    }
                } else {
                    for (var i = 1; i <= vm.commonarea.quantityGuestLimit; i++) {
                        var space = {value: i, description: "", disabled: false};
                        vm.spacesFree.push(space);
                    }
                }
            }
        }

        function addBlocksToSelect() {
            vm.hours = [];
            vm.commonAreaReservations.initalDate.setHours(0);
            vm.commonAreaReservations.initalDate.setMinutes(0);
            var initialTime = vm.commonAreaReservations.initalDate;
            vm.commonAreaReservations.initalDate.setHours(23);
            vm.commonAreaReservations.initalDate.setMinutes(59);
            var finalTime = vm.commonAreaReservations.initalDate;
            CommonAreaReservations.findReservedSpotByCommonArea({
                date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                commonAreaId: CommonMethods.encryptS(vm.commonarea.id),
                page: 0,
                size: 500
            }, onSuccess, onError);

            function onSuccess(data) {
                var arreglo = vm.daySelected.times;
                angular.forEach(arreglo, function (block, index) {
                    var reservationCount = 0;
                    var spacesOcupated = 0;
                    angular.forEach(data, function (reservation, index) {
                        if (reservation.initialTime == block.initialValue) {
                            reservationCount++;
                        }
                        spacesOcupated = spacesOcupated + reservation.peopleQuantiy + 1;
                    });
                    if (spacesOcupated > 1) {
                        var freeSpaces = vm.commonarea.maxCapacity - spacesOcupated;
                    } else {
                        var freeSpaces = 1;
                    }
                    if (vm.blockedByAdmin(block, vm.commonAreaReservations.initalDate)) {
                        block.isAvailable = " - BLOQUEADO";
                        block.disabled = true;
                    }
                    if (reservationCount > 0 && reservationCount >= vm.commonarea.limitPeoplePerReservation || freeSpaces == 0) {
                        block.isAvailable = " - RESERVADO";
                        block.disabled = true;
                    }
                    vm.hours.push(block);
                });
            }
        }

        function addHoursToSelect() {
            vm.hours = [];
            var min = parseFloat(vm.daySelected.initialValue);
            var max = parseFloat(vm.daySelected.finalValue);
            var top = max;
            if (esEntero(max)) {
                top = max;
            } else {
                var round = Math.round(max);
                top = round > top ? round - 1 : round;
            }
            if (esEntero(min)) {
            } else {
                var roundMin = Math.round(min);
                var minTop = roundMin > min ? roundMin - 1 : roundMin;
                var diff = ((roundMin - min) < 0 ? -(roundMin - min) : (roundMin - min)).toFixed(2);
                if (min <= 12) {
                    if (diff == 0.15) {
                        var item = {
                            value: minTop + 0.15,
                            time: minTop + ':15 AM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: minTop + 0.5,
                            time: minTop + ':30 AM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: minTop + 0.75,
                            time: minTop + ':45 AM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.5) {
                        var item = {
                            value: minTop + 0.5,
                            time: minTop + ':30 AM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: minTop + 0.75,
                            time: minTop + ':45 AM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.75) {
                        var item = {
                            value: minTop + 0.75,
                            time: minTop + ':45 AM',
                        };
                        vm.hours.push(item);
                    }
                } else {
                    if (diff == 0.15) {
                        var rest = minTop != 12 ? 12 : 0;
                        var item = {
                            value: minTop + 0.15,
                            time: minTop - rest + ':15 PM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: minTop + 0.5,
                            time: minTop - rest + ':30 PM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: minTop + 0.75,
                            time: minTop - rest + ':45 PM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.5) {
                        vm.hours.push(item);
                        var item = {
                            value: minTop + 0.5,
                            time: minTop - rest + ':30 PM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: minTop + 0.75,
                            time: minTop - rest + ':45 PM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.75) {
                        var item = {
                            value: minTop + 0.75,
                            time: minTop - rest + ':45 PM',
                        };
                        vm.hours.push(item);
                    }
                }
                min = roundMin < min ? roundMin + 1 : roundMin;
            }
            for (var i = min; i <= top; i++) {
                if (i < 12) {
                    if (i == 0) {
                        var item = {value: i, half: 0, time: '12:00 AM'};
                        vm.hours.push(item);
                        if (i != top) {
                            if (vm.commonarea.allowFifteenMin) {
                                var item1 = {value: i + 0.15, half: 15, time: '12:15 AM'};
                                vm.hours.push(item1);
                            }
                            if (vm.commonarea.allowHalfHours) {
                                var item2 = {value: i + 0.5, half: 30, time: '12:30 AM'};
                                vm.hours.push(item2);
                            }
                            if (vm.commonarea.allowFifteenMin) {
                                var item3 = {value: i + 0.75, half: 45, time: '12:45 AM'};
                                vm.hours.push(item3);
                            }
                        }
                    } else {
                        var item = {value: i, half: 0, time: i + ':00 AM'};
                        vm.hours.push(item);
                        if (i != top) {
                            if (vm.commonarea.allowFifteenMin) {
                                var item1 = {value: i + 0.15, half: 15, time: i + ':15 AM'};
                                vm.hours.push(item1);
                            }
                            if (vm.commonarea.allowHalfHours) {
                                var item2 = {value: i + 0.5, half: 30, time: i + ':30 AM'};
                                vm.hours.push(item2);
                            }
                            if (vm.commonarea.allowFifteenMin) {
                                var item3 = {value: i + 0.75, half: 45, time: i + ':45 AM'};
                                vm.hours.push(item3);
                            }
                        }
                    }
                } else {
                    if (i == 12) {
                        var item = {value: i, half: 0, time: i + ':00 PM'};
                        vm.hours.push(item);
                        if (i != top) {
                            if (vm.commonarea.allowFifteenMin) {
                                var item1 = {value: i + 0.15, half: 15, time: i + ':15 PM'};
                                vm.hours.push(item1);
                            }
                            if (vm.commonarea.allowHalfHours) {
                                var item2 = {value: i + 0.5, half: 30, time: i + ':30 PM'};
                                vm.hours.push(item2);
                            }
                            if (vm.commonarea.allowFifteenMin) {
                                var item3 = {value: i + 0.75, half: 45, time: i + ':45 PM'};
                                vm.hours.push(item3);
                            }
                        }
                    } else {
                        var item = {value: i, half: 0, time: i - 12 + ':00 PM'};
                        vm.hours.push(item);
                        if (i != top) {
                            if (vm.commonarea.allowFifteenMin) {
                                var item1 = {value: i + 0.15, half: 15, time: i - 12 + ':15 PM'};
                                vm.hours.push(item1);
                            }
                            if (vm.commonarea.allowHalfHours) {
                                var item2 = {value: i + 0.5, half: 30, time: i - 12 + ':30 PM'};
                                vm.hours.push(item2);
                            }
                            if (vm.commonarea.allowFifteenMin) {
                                var item3 = {value: i + 0.75, half: 45, time: i - 12 + ':45 PM'};
                                vm.hours.push(item3);
                            }
                        }
                    }
                }
            }
            if (esEntero(max)) {
            } else {
                var roundMax = Math.round(max);
                var maxTop = roundMax > max ? roundMax - 1 : roundMax;
                var diff = ((maxTop - max) < 0 ? -(maxTop - max) : (maxTop - max)).toFixed(2);
                if (maxTop <= 12) {
                    var item = {
                        value: maxTop,
                        time: maxTop + ':00 AM',
                    };
                    vm.hours.push(item);
                    if (diff == 0.15) {
                        var item = {
                            value: maxTop,
                            time: maxTop + ':15 AM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.5) {
                        var item = {
                            value: maxTop + 0.15,
                            time: maxTop + ':15 AM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: maxTop + 0.5,
                            time: maxTop + ':30 AM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.75) {
                        var item = {
                            value: maxTop + 0.15,
                            time: maxTop + ':15 AM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: maxTop + 0.5,
                            time: maxTop + ':30 AM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: maxTop + 0.75,
                            time: maxTop + ':45 AM',
                        };
                        vm.hours.push(item);
                    }
                } else {
                    var item = {
                        value: maxTop,
                        time: maxTop - 12 + ':00 PM',
                    };
                    vm.hours.push(item);
                    if (diff == 0.15) {
                        var item = {
                            value: maxTop + 0.15,
                            time: maxTop - 12 + ':15 PM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.5) {
                        var item = {
                            value: maxTop + 0.15,
                            time: maxTop - 12 + ':15 PM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: maxTop + 0.5,
                            time: maxTop - 12 + ':30 PM',
                        };
                        vm.hours.push(item);
                    }
                    if (diff == 0.75) {
                        var item = {
                            value: maxTop + 0.15,
                            time: maxTop - 12 + ':15 PM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: maxTop + 0.5,
                            time: maxTop - 12 + ':30 PM',
                        };
                        vm.hours.push(item);
                        var item = {
                            value: maxTop + 0.75,
                            time: maxTop - 12 + ':45 PM',
                        };
                        vm.hours.push(item);
                    }
                }
            }

            vm.commonAreaReservations.initalDate.setHours(0);
            vm.commonAreaReservations.initalDate.setMinutes(0);
            var initialTime = vm.commonAreaReservations.initalDate;
            vm.commonAreaReservations.initalDate.setHours(23);
            vm.commonAreaReservations.initalDate.setMinutes(59);
            var finalTime = vm.commonAreaReservations.initalDate;
            CommonAreaReservations.findReservedSpotByCommonArea({
                date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                commonAreaId: CommonMethods.encryptS(vm.commonarea.id),
                page: 0,
                size: 500
            }, onSuccess, onError);

            function onSuccess(data) {
                angular.forEach(vm.hours, function (block, index) {
                    var reservationCount = 0;
                    var spacesOcupated = 0;
                    angular.forEach(data, function (reservation, index) {
                        if (parseFloat(reservation.finalTime) > block.value && parseFloat(reservation.initialTime) <= block.value) {
                            reservationCount++;
                            if (reservation.peopleQuantity) {
                                spacesOcupated = spacesOcupated + reservation.peopleQuantity;
                            }
                        }
                    });

                    if (spacesOcupated > 1) {
                        var freeSpaces = vm.commonarea.maxCapacity - spacesOcupated;
                    } else {
                        var freeSpaces = 1;
                    }
                    if (!vm.commonarea.hasMaxCapacity) {
                        var freeSpaces = 1;
                    }
                    if (vm.blockedByAdmin(block, vm.commonAreaReservations.initalDate)) {
                        block.isAvailable = " - BLOQUEADO";
                        block.disabled = true;
                    }
                    if (reservationCount > 0 && reservationCount >= vm.commonarea.limitPeoplePerReservation || freeSpaces <= 0) {
                        block.isAvailable = " - RESERVADO";
                        block.disabled = true;
                    }
                });
            }


            if (vm.commonAreaReservations.id != null) {
                angular.forEach(vm.hours, function (item, index) {

                    if (item.value == vm.commonAreaReservations.initialTime) {
                        vm.timeSelected.initialTime = vm.hours[index];
                        vm.validateDaysInitialHours(vm.timeSelected.initialTime, index);
                    }
                    if (item.value == vm.commonAreaReservations.finalTime) {
                        vm.timeSelected.finalTime = vm.hours[index];
                    }
                });
            }
        }

        vm.blockedByAdmin = function (block, date) {
            var today = new Date();
            if (vm.blockingHours != undefined) {
                for (var i = 0; i < vm.blockingHours.length; i++) {
                    var blocking = vm.blockingHours[i];
                    if (blocking.scheduled == false) {
                        var specificDate = new Date(blocking.specificDay);
                        if (date.getDate() == specificDate.getDate() && date.getMonth() == specificDate.getMonth() && date.getFullYear() == specificDate.getFullYear()) {
                            var hours = blocking.hours.split(",");
                            if (vm.commonarea.hasBlocks == 1) {
                                if (parseFloat(block.initialValue) >= parseFloat(hours[0]) && parseFloat(block.finalValue) <= Math.round(parseFloat(hours[1]))) {
                                    return true;
                                }
                                if (parseFloat(block.finalValue) >= parseFloat(hours[1]) && parseFloat(block.initialValue) <= Math.round(parseFloat(hours[1]))) {
                                    return true;
                                }
                            } else {
                                if (parseFloat(block.value) >= parseFloat(hours[0]) && parseFloat(block.value) < parseFloat(hours[1])) {
                                    return true;
                                }
                            }
                        }
                    } else {
                        var days = blocking.days.split("|");
                        for (var j = 0; j < days.length; j++) {
                            var day = days[j];
                            if (day != "") {
                                var hours = day.split(";")[1].split(",");
                                var day = day.split(";")[0];
                                if (vm.isDayOfWeek(day, date)) {
                                    if (vm.commonarea.hasBlocks == 1) {
                                        if (parseFloat(block.initialValue) >= parseFloat(hours[0]) && parseFloat(block.finalValue) <= parseFloat(hours[1])) {
                                            return true;
                                        }
                                    } else {
                                        if (block.value >= hours[0] && block.value < hours[1]) {
                                            return true;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return false;
        };

        vm.isDayOfWeek = function (day, date) {
            var weekDay = date.getDay();
            var weekDayDate = "";
            switch (day) {
            case "L":
                weekDayDate = 1;
                break;
            case "M":
                weekDayDate = 2;
                break;
            case "X":
                weekDayDate = 3;
                break;
            case "J":
                weekDayDate = 4;
                break;
            case "V":
                weekDayDate = 5;
                break;
            case "S":
                weekDayDate = 6;
                break;
            case "D":
                weekDayDate = 0;
                break;
            }
            return weekDayDate == weekDay;
        };

        vm.residentsByHouse = function () {
            Resident.getResidentsByHouse({
                houseId: globalCompany.getHouseId()
            }).$promise.then(onSuccessResidents, onError);

        };
        vm.defineRoleUser = function (resident) {
            for (var i = 0; i < resident.houses.length; i++) {
                switch (resident.houses[i].roleResident) {
                case "ROLE_OWNER":
                    resident.houses[i].role = "Residente propietario";
                    break;
                case "ROLE_RENTER":
                    resident.houses[i].role = "Propietario arrendador";
                    break;
                case "ROLE_TENANT":
                    resident.houses[i].role = "Inquilino";
                    break;
                case "ROLE_RESIDENT":
                    resident.houses[i].role = "Residente autorizado";
                    break;
                }
            }
            return resident;
        };

        function defineRole(role) {
            switch (role) {
            case "ROLE_OWNER":
                return "Residente propietario";
            case "ROLE_RENTER":
                return "Propietario arrendador";
            case "ROLE_TENANT":
                return "Inquilino";
            case "ROLE_RESIDENT":
                return "Residente autorizado";
            }
            return "ROLE_OWNER";
        }

        function formatResidentList(role, data) {
            var list = [];
            for (var i = 0; i < data.length; i++) {
                var r = data[i];
                for (var j = 0; j < r.residentHousePK.length; j++) {
                    var h = r.residentHousePK[j];
                    if (h.houseId == globalCompany.getHouseId()) {
                        r.role = h.role;
                    }
                }
                if (r.role == role) {
                    r.roleDescription = defineRole(r.role);
                    r = vm.defineRoleUser(r);
                    list.push(r);
                }
            }
            return list;
        }

        function onSuccessResidents(data) {

            angular.forEach(data, function (value, key) {
                value.name = value.name + " " + value.lastname;
            });
            vm.residents = [];
            vm.residents = vm.residents.concat(formatResidentList("ROLE_OWNER", data));
            vm.residents = vm.residents.concat(formatResidentList("ROLE_TENANT", data));
            vm.residents = vm.residents.concat(formatResidentList("ROLE_RESIDENT", data));
            House.get({
                id: globalCompany.getHouseId()
            }, function (result) {
                vm.houseSelected = result;
            });
        }

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function onError(error) {
            AlertService.error(error.data.message);
            Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.unexpectedError")); //"Ocurrio un error inesperado.")
        }

        function onErrorSchedule(error) {
            AlertService.error(error.data.message);

        }


        function createReservation(commonAreaReservations) {
            Modal.showLoadingBar();
            vm.isSaving = true;
            vm.commonAreaReservations.sendPendingEmail = true;
            vm.commonAreaReservations.reservationCharge = vm.commonarea.reservationCharge;
            vm.commonAreaReservations.devolutionAmmount = vm.commonarea.devolutionAmmount;
            vm.commonAreaReservations.commonAreaId = vm.commonarea.id;
            vm.commonAreaReservations.paymentProof = commonAreaReservations;
            vm.commonAreaReservations.houseId = globalCompany.getHouseId();
            vm.commonAreaReservations.companyId = globalCompany.getId();
            vm.commonAreaReservations.status = 1;
            if (!vm.commonAreaReservations.residentId) {
                vm.commonAreaReservations.residentId = $rootScope.companyUser.id;
            }
            vm.commonAreaReservations.dateString = CommonMethods.getDateString(vm.commonAreaReservations.initalDate);
            if (vm.commonarea.maximunHours == 0 && vm.commonarea.hasBlocks == 0) {
                vm.commonAreaReservations.initialTime = vm.daySelected.initialValue;
                vm.commonAreaReservations.finalTime = vm.daySelected.finalValue;
            } else if (vm.commonarea.maximunHours > 0 && vm.commonarea.hasBlocks == 0) {
                vm.commonAreaReservations.initialTime = vm.timeSelected.initialTime.value;
                vm.commonAreaReservations.finalTime = vm.timeSelected.finalTime.value;
                vm.commonAreaReservations.initalDate.setHours(0);
                vm.commonAreaReservations.initalDate.setMinutes(0);
            } else if (vm.commonarea.hasBlocks == 1) {
                vm.commonAreaReservations.initialTime = vm.timeSelected.initialValue;
                vm.commonAreaReservations.finalTime = vm.timeSelected.finalValue;
                vm.commonAreaReservations.initalDate.setHours(0);
                vm.commonAreaReservations.initalDate.setMinutes(0);
            }
            if (vm.commonAreaReservations.id !== null) {
                vm.commonAreaReservations.initalDate = new Date(vm.commonAreaReservations.initalDate);
                vm.commonAreaReservations.initalDate.setHours(0);
                vm.commonAreaReservations.initalDate.setMinutes(0);
                vm.commonAreaReservations.sendPendingEmail = false;
                CommonAreaReservations.update(vm.commonAreaReservations, onSaveSuccess, onSaveError);
            } else {

                vm.commonAreaReservations.status = 1;
                vm.commonAreaReservations.houseId = globalCompany.getHouseId();
                vm.commonAreaReservations.companyId = globalCompany.getId();
                if (vm.commonarea.chargeRequired == 0) {
                    vm.commonAreaReservations.reservationCharge = null;
                }
                CommonAreaReservations.save(vm.commonAreaReservations, onSaveSuccess, onSaveError);
            }


        }


        function onSaveSuccess(result) {
            Modal.hideLoadingBar();
            $state.go('common-area-all-reservations-resident-view');
            Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.reservationSentForApproval")); //"Se ha enviado la reservación correctamente para su respectiva aprobación")
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
            Modal.hideLoadingBar();
        }

        vm.datePickerOpenStatus.date = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        vm.picker = {
            datepickerOptions: {
                minDate: moment().subtract(1, 'days').startOf(new Date()),
                enableTime: false,
                showWeeks: false,
                daysOfWeekDisabled: [0, 1, 2, 3, 4, 5, 6],
                clearBtn: false,
                todayBtn: false
            }
        };

        function confirmMessage() {
            if (vm.notAllowPaymentProof()) {
                Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.paymentReceiptRequired"));
            } else {
                if (!vm.isMorosa && !vm.blockReservation.blocked) {
                    if (vm.scheduleIsAvailable) {
                        if (vm.commonarea.maximunHours == 0) {
                            vm.time = vm.timeSelected.time; // Usa el valor directamente
                        } else if (vm.commonarea.maximunHours > 0 && vm.commonarea.hasBlocks == 0) {
                            vm.time = vm.timeSelected.initialTime.time + " - " + vm.timeSelected.finalTime.time; // Usa el valor directamente
                        } else if (vm.commonarea.hasBlocks == 1) {
                            vm.time = vm.timeSelected.time; // Usa el valor directamente
                        }

                        var assistanceMessage = vm.commonAreaReservations.peopleQuantity ? '<h4>' + translate.instant("aditumApp.commonAreaReservations.form.totalAttendanceIncludes", { quantity: vm.commonAreaReservations.peopleQuantity }) + '</h4>' : '';
                        bootbox.confirm({
                            message: '<div class="text-center gray-font font-15"><h4 style="margin-bottom:30px;">' + translate.instant("aditumApp.commonAreaReservations.form.confirmMessage") + '</h4><h4>' + translate.instant("aditumApp.commonAreaReservations.form.commonArea") + ': <span class="bold" id="commonArea"></span> </h4><h4>' + translate.instant("aditumApp.commonAreaReservations.form.reservationDate") + ': <span class="bold" id="reservationDate"></span> </h4><h4>' + translate.instant("aditumApp.commonAreaReservations.form.time") + ': <span class="bold" id="time"></span> </h4>'
                                    + assistanceMessage
                                    + '</div>',
                            buttons: {
                                confirm: {
                                    label: translate.instant("aditumApp.commonAreaReservations.form.reserve"),
                                    className: 'btn-success'
                                },
                                cancel: {
                                    label: translate.instant("aditumApp.commonAreaReservations.form.cancel"),
                                    className: 'btn-danger'
                                }
                            },
                            callback: function (result) {
                                vm.isSaving = true;

                                var initialTime = "";
                                var finalTime = "";
                                if (vm.commonarea.hasBlocks == 1) {
                                    initialTime = vm.timeSelected.initialValue;
                                    finalTime = vm.timeSelected.finalValue;
                                } else {
                                    initialTime = vm.timeSelected.initialTime.value;
                                    finalTime = vm.timeSelected.finalTime.value;
                                }
                                CommonAreaReservations.isAvailableToReserve({
                                    maximun_hours: vm.commonarea.maximunHours,
                                    reservation_date: CommonMethods.getDateString(vm.commonAreaReservations.initalDate),
                                    initial_time: initialTime,
                                    final_time: finalTime,
                                    common_area_id: vm.commonarea.id,
                                    house_id: globalCompany.getHouseId(),
                                }, function (data) {
                                    if (data.availability != 0) {
                                        Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.spaceUnavailable"));
                                        $state.reload();
                                    } else {
                                        if (result) {
                                            vm.paymentProofId = null;
                                            vm.isSaving = true;
                                            if (vm.file) {
                                                upload();
                                            } else {
                                                createReservation(null);
                                            }
                                        } else {
                                            vm.isSaving = false;
                                        }
                                    }
                                }, onError);
                            }
                        });
                        document.getElementById("commonArea").innerHTML = vm.commonarea.name;
                        document.getElementById("reservationDate").innerHTML = vm.diasDeLaSemana[vm.commonAreaReservations.initalDate.getDay()] + " " + vm.commonAreaReservations.initalDate.getDate() + translate.instant("aditumApp.commonAreaReservations.form.of") + vm.mesesDelAnno[vm.commonAreaReservations.initalDate.getMonth()] + translate.instant("aditumApp.commonAreaReservations.form.of") + vm.commonAreaReservations.initalDate.getFullYear();
                        document.getElementById("time").innerHTML = vm.time;

                    } else {
                        if (vm.commonarea.maximunHours !== 0) {
                            if (vm.timeSelected.finalTime.isValid == false) {
                                Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.endTimeAfterStart"));
                            } else {
                                Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.selectedHoursOccupied2"));
                            }
                        } else {
                            Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.selectedHoursOccupied"));
                        }
                    }
                } else {
                    if (vm.isMorosa) {
                        Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.branchInArrears"));
                    } else {
                        Modal.toast(translate.instant("aditumApp.commonAreaReservations.form.reservationsBlocked"));
                    }
                }
            }
        }

        vm.validateReservationCharge = function (commonArea) {
            var s = commonArea.reservationCharge;
            var caracteres = ['´', 'Ç', '_', 'ñ', 'Ñ', '¨', ';', '{', '}', '[', ']', '"', "¡", "!", "¿", "<", ">", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", ",", ".", "?", "/", "-", "+", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "=", "|"];

            var invalido = 0;
            angular.forEach(caracteres, function (val, index) {
                if (s != undefined) {
                    for (var i = 0; i < s.length; i++) {
                        if (s.charAt(i).toUpperCase() == val.toUpperCase() || s == undefined) {
                            invalido++;
                        }
                    }
                }
            });
            if (invalido == 0) {
                commonArea.reservationChargeValida = true;
            } else {
                commonArea.reservationChargeValida = false;
            }
        };

        vm.validateDevolutionAmmount = function (commonArea) {
            var s = commonArea.devolutionAmmount;
            var caracteres = ['´', 'Ç', '_', 'ñ', 'Ñ', '¨', ';', '{', '}', '[', ']', '"', "¡", "!", "¿", "<", ">", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", ",", ".", "?", "/", "-", "+", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "=", "|"];

            var invalido = 0;
            angular.forEach(caracteres, function (val, index) {
                if (s != undefined) {
                    for (var i = 0; i < s.length; i++) {
                        if (s.charAt(i).toUpperCase() == val.toUpperCase() || s == undefined) {
                            invalido++;
                        }
                    }
                }
            });
            if (invalido == 0) {
                commonArea.devolutionAmmountValida = true;
            } else {
                commonArea.devolutionAmmountValida = false;
            }
        };
        vm.datePickerOpenStatus.date = false;
        vm.datePickerOpenStatus.paymentDate = false;
        vm.datePickerOpenStatus.expirationDate = false;
        vm.setFile = function ($file) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                vm.file = $file;
                vm.fileName = vm.file.name;
                file = $file;
            }
        };

    }
}

)();
