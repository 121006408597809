(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingFullPeriodController', AccountingFullPeriodController);

    AccountingFullPeriodController.$inject = ['AccountingFullPeriod', 'globalCompany', '$state', 'CommonMethods', 'Modal', '$rootScope'];

    function AccountingFullPeriodController(AccountingFullPeriod, globalCompany, $state, CommonMethods, Modal, $rootScope) {

        var vm = this;
        vm.isReady = false;
        vm.accountingFullPeriods = [];
        $rootScope.active = "accountingPeriod";

        loadAll();
        vm.createMonthClosing = function (period) {
            if (period.accountingPeriods.length == 0) {
                Modal.confirmDialog("¿Está seguro que desea crear el cierre mensual?", "Es NECESARIO que haya configurado las cuentas contables por completo antes de crear el primer cierre mensual", function () {
                    var periodId = CommonMethods.encryptIdUrl(period.id);
                    $state.go('accounting-full-period.newMonthClosing', {periodId: periodId});
                });
            } else {
                var periodId = CommonMethods.encryptIdUrl(period.id);
                $state.go("accounting-full-period.newMonthClosing", {periodId: periodId});
            }
        };
        vm.detailMonth = function (period) {
            var id = CommonMethods.encryptIdUrl(period.id);
            $state.go('accounting-period-detail', {id: id});
        };
        vm.closePeriod = function (period) {
            Modal.confirmDialog("¿Está seguro que desea realizar el cierre contable?", "Se crearán los asientos de cierre y el nuevo periodo", function () {
                var id = CommonMethods.encryptIdUrl(period.id);
                $state.go('accounting-full-period.close', {lastPeriodId: id});
            });
        };


        vm.reOpenPeriod = function (period) {
            Modal.confirmDialog("¿Está seguro que desea volver a abrir el periodo contable?", "Esto modificará el saldo de todos las cuentas del cierre si se realiza algún nuevo movimiento contable dentro del período.", function () {
                Modal.confirmDialog("¿Por favor confirme que desea volver a abrir el periodo contable?", "Esto modificará el saldo de todos las cuentas del cierre si se realiza algún nuevo movimiento contable dentro del período.", function () {
                    Modal.showLoadingBar();
                    AccountingFullPeriod.reOpenPeriod({periodId: period.id}, function () {
                        vm.accountingFullPeriods = [];
                        loadAll();
                    });
                });
            });
        };
        vm.deletePeriod = function (period) {
            Modal.confirmDialog("¿Está seguro que desea eliminar el periodo contable?", "Será necesario volver a crearlo nuevamente para realizar el cierre correspondiente.", function () {
                Modal.confirmDialog("¿Por favor confirme que desea eliminar el periodo contable?", "Esto modificará el saldo de todos las cuentas del cierre si se realiza algún nuevo movimiento contable dentro del período.", function () {
                    Modal.showLoadingBar();
                    AccountingFullPeriod.deleteOpenPeriod({periodId: period.id}, function () {
                        vm.accountingFullPeriods = [];
                        loadAll();
                    });
                });
            });
        };

        function loadAll() {
            AccountingFullPeriod.findByCompany({companyId: globalCompany.getId()}, function (result) {
                for (var i = 0; i < result.length; i++) {
                    result[i].isOpen = i === result.length - 1;
                    result[i].haveAnyPeriodOpen = vm.haveAnyPeriodOpen(result[i]);
                    vm.accountingFullPeriods.push(result[i]);
                }
                Modal.hideLoadingBar();
                vm.isReady = true;
            });
        }

        vm.haveAnyPeriodOpen = function (period) {
            var isAnyOpen = 0;
            for (var i = 0; i < period.accountingPeriods.length; i++) {
                if (period.accountingPeriods[i].status == 1) {
                    isAnyOpen++;
                }
            }
            return isAnyOpen != 0;
        };
    }
})();
