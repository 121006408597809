(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('MensualChargeController', MensualChargeController);

    MensualChargeController.$inject = ['AccountingPeriod', '$timeout', 'CustomChargeType', 'ExchangeRateBccr', 'BitacoraAcciones', '$state', 'House', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$rootScope', '$scope', 'AdministrationConfiguration', 'Charge', 'CommonMethods', 'globalCompany', 'Modal'];

    function MensualChargeController(AccountingPeriod, $timeout, CustomChargeType, ExchangeRateBccr, BitacoraAcciones, $state, House, ParseLinks, AlertService, paginationConstants, pagingParams, $rootScope, $scope, AdministrationConfiguration, Charge, CommonMethods, globalCompany, Modal) {
        var vm = this;
        $rootScope.active = 'mensual';
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.bccrUse = true;
        vm.radiostatus = true;
        vm.cuotaFija = true;
        vm.isReady = false;
        vm.sendEmail = false;
        vm.tipoCuota = 1;
        vm.today = new Date();
        $rootScope.mainTitle = "Generar facturación masiva";
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.verificando = false;
        vm.creatingCharges = false;
        vm.chargeCount = 0;
        moment.locale("es");
        vm.tipoCambio = {};

        AccountingPeriod.getLastPeriodCloseDate({companyId: globalCompany.getId()}, function (result) {
            var minDate = new Date(result.date);
            vm.notFound = false;
            vm.closePeriodDate = minDate;
            var lastDayClose = new Date(minDate.getFullYear(), minDate.getMonth() + 1, 1);
            $timeout(function () {
                $scope.$apply(function () {
                    vm.minDate = lastDayClose;
                });
            });
        }, function (notFound) {
            vm.notFound = true;
        });

        vm.secondCurrency = vm.companyConfig.secondCurrency;
        vm.principalCurrency = vm.companyConfig.currency;
        vm.currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
        vm.exchangeRateCurrency = CommonMethods.getExchangeRateCurrency(vm.currencies);

        vm.showBccrUse = function () {
            var currencies = CommonMethods.getCompanyCurrencies(globalCompany.getId());
            if (currencies.length !== 2) {
                return false;
            }
            var showBccrUse = currencies.some(function (moneda) {
                return moneda.symbol === "₡";
            }) && currencies.some(function (moneda) {
                return moneda.symbol === "$";
            });
            return showBccrUse;
        };

        CustomChargeType.getByCompany({companyId: globalCompany.getId()}, function (result) {
            vm.customChargeTypes = result;
            vm.chargeType = defineMaintenance(result);
        });

        function defineMaintenance(list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].type == 1) {
                    return list[i];
                }
            }
        }

        vm.Today = new Date();

        vm.validate = function (cuota) {
            var s = cuota.ammount;
            var caracteres = ['´', 'Ç', '_', 'ñ', 'Ñ', '¨', ';', '{', '}', '[', ']', '"', "¡", "!", "¿", "<", ">", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", ",", ".", "?", "/", "-", "+", "@", "#", "$", "%", "^", "&", "*", "(", ")", "-", "_", "=", "|"];
            var invalido = 0;
            angular.forEach(caracteres, function (val, index) {
                if (s != undefined) {
                    for (var i = 0; i < s.length; i++) {
                        if (s.charAt(i).toUpperCase() == val.toUpperCase()) {
                            invalido++;
                        }
                    }
                }
            });
            if (invalido == 0) {
                cuota.valida = true;
            } else {
                cuota.valida = false;
            }
        };
        loadAll();
        vm.verificarCargos = function () {
            var invalid = 0;
            angular.forEach(vm.houses, function (house, key) {
                angular.forEach(house.cuotas, function (cuota, key) {
                    if (cuota.valida == false) {
                        invalid++;
                    }
                });
            });
            if (invalid == 0) {
                vm.verificando = true;
            } else {
                Modal.toast("Porfavor verifica las cuotas ingresadas");
            }
        };
        vm.cancelar = function () {
            vm.verificando = false;
        };
        vm.addNewDue = function () {
            vm.globalConceptNumber++;
            vm.globalConcept.push({
                date: "",
                concept: "",
                id: vm.globalConceptNumber,
                datePickerOpenStatus: false
            });
            angular.forEach(vm.houses, function (value, key) {

                if (value.due == undefined && vm.cuotaFija == true) {
                    value.cuotas.push({
                        ammount: 0,
                        globalConcept: vm.globalConceptNumber,
                        valida: true
                    });

                } else {
                    if (vm.cuotaFija == false) {
                        value.cuotas.push({
                            ammount: value.squareMeters * vm.adminConfig.squareMetersPrice,
                            globalConcept: vm.globalConceptNumber
                        });
                    } else {
                        value.cuotas.push({
                            ammount: value.due,
                            globalConcept: vm.globalConceptNumber
                        });
                    }
                }
            });
        };

        vm.definirMantenimiento = function () {
            vm.tipoCuota = 1;
            angular.forEach(vm.houses, function (house, key) {
                angular.forEach(house.cuotas, function (cuota, key) {
                    cuota.ammount = vm.formatCurrencyToPay(house.due);
                });
            });
        };
        vm.definirOtraCuota = function () {
            vm.tipoCuota = 2;
            angular.forEach(vm.houses, function (house, key) {
                angular.forEach(house.cuotas, function (cuota, key) {
                    cuota.ammount = vm.newTotalAmmount;
                });
            });
        };

        function getGlobalConcept(gc) {
            var globalFounded = {};
            angular.forEach(vm.globalConcept, function (globalConcept, i) {
                if (gc == globalConcept.id) {
                    globalFounded = globalConcept;
                }
            });
            return globalFounded;
        }

        function buildCharge(cuota, house) {
            cuota.houseId = parseInt(house.id);
            cuota.houseNumber = house.housenumber;
            cuota.type = vm.chargeType.type;
            cuota.date = getGlobalConcept(cuota.globalConcept).date;
            cuota.concept = getGlobalConcept(cuota.globalConcept).concept;
            cuota.dueDate = getGlobalConcept(cuota.globalConcept).dueDate;
            cuota.state = 1;
            cuota.deleted = 0;
            cuota.companyId = globalCompany.getId();
            if (vm.sendEmail) {
                cuota.sendEmail = true;
            }
            return cuota;
        }

        vm.createDues = function () {
            Modal.showLoadingBar();
            var selectedHouses = "";
            vm.creatingCharges = true;
            vm.totalCharges = [];
            for (var i = 0; i < vm.houses.length; i++) {
                for (var j = 0; j < vm.houses[i].cuotas.length; j++) {
                    vm.totalCharges.push(buildCharge(vm.houses[i].cuotas[j], vm.houses[i]));
                }
            }


            var total = {charges: vm.totalCharges};
            total.currency = vm.adminConfig.chargesCollectCurrency;
            total.exchangeRate = vm.tipoCambioVenta;
            Charge.saveAllMaintenanceCharges(total, function (data) {
                Modal.hideLoadingBar();
                Modal.actionToastStay("Listo! Se está generando la facturación, en unos minutos se habrá facturado la totalidad.", "Entendido", function () {
                });
                vm.creatingCharges = false;
                vm.verificando = false;
            });

            function createCharge(houseNumber, cuotaNumber) {
                setTimeout(function () {
                    $scope.$apply(function () {
                        vm.chargeCount = houseNumber;
                    });
                }, 10);
                var cuota = vm.houses[houseNumber].cuotas[cuotaNumber];
                var cuotaNumber = cuotaNumber;
                var house = vm.houses[houseNumber];

                if (cuota.ammount != 0) {
                    Charge.save(buildCharge(cuota, house), function (result) {
                        selectedHouses = selectedHouses + house.housenumber + ", ";
                        if (house.cuotas.length - 1 > cuotaNumber) {
                            createCharge(houseNumber, cuotaNumber + 1);
                        } else {
                            if (vm.houses.length - 1 > houseNumber) {
                                chargesPerHouse(houseNumber + 1);
                            } else {
                                $state.go('mensualCharge', null, {
                                    reload: true
                                });
                                angular.forEach(vm.globalConcept, function (value, key) {
                                    var housesSelected = "";
                                    angular.forEach(vm.houses, function (house, key) {
                                        angular.forEach(house.cuotas, function (cuota, key) {
                                            if (cuota.ammount > 0 && vm.globalConcept[cuota.globalConcept].id == value.id) {
                                                housesSelected = housesSelected + house.housenumber + ",";
                                            }
                                        });
                                    });
                                    var concept = "Creación de cuota mensual" + ": " + value.concept + ", a las filiales " + housesSelected;
                                    BitacoraAcciones.save(mapBitacoraAcciones(concept), function () {
                                    });
                                });
                                Modal.hideLoadingBar();
                                Modal.toast("Se generaron las cuotas correctamente.");
                                vm.creatingCharges = false;
                            }
                        }
                    }, function () {
                        Modal.hideLoadingBar();
                        Modal.toast("Hubo un error al crear las cuotas mensuales, por favor verificar los datos ingresados.");
                    });
                } else {
                    if (house.cuotas.length - 1 > cuotaNumber) {
                        createCharge(houseNumber, cuotaNumber + 1);
                    } else {
                        if (vm.houses.length - 1 > houseNumber) {
                            chargesPerHouse(houseNumber + 1);
                        } else {
                            $state.go('mensualCharge', null, {
                                reload: true
                            });
                            Modal.hideLoadingBar();
                            angular.forEach(vm.globalConcept, function (value, key) {
                                var housesSelected = "";
                                angular.forEach(vm.houses, function (house, key) {
                                    angular.forEach(house.cuotas, function (cuota, key) {
                                        if (cuota.ammount > 0 && vm.globalConcept[cuota.globalConcept].id == value.id) {
                                            housesSelected = housesSelected + house.housenumber + ",";
                                        }
                                    });
                                });
                                var concept = "Creación de cuota mensual" + ": " + value.concept + ", a las filiales " + housesSelected;
                                BitacoraAcciones.save(mapBitacoraAcciones(concept), function () {
                                });
                            });
                            Modal.toast("Se generaron las cuotas correctamente.");
                        }
                    }
                }
            }

            function chargesPerHouse(houseNumber) {
                var cuotaNumber = 0;
                createCharge(houseNumber, cuotaNumber);
            }

            // chargesPerHouse(0)
        };


        function mapBitacoraAcciones(concept) {
            vm.bitacoraAcciones = {};
            vm.bitacoraAcciones.concept = concept;
            vm.bitacoraAcciones.type = 6;
            vm.bitacoraAcciones.ejecutionDate = new Date();
            vm.bitacoraAcciones.category = "Cuotas";
            vm.bitacoraAcciones.idReference = 1;
            vm.bitacoraAcciones.companyId = globalCompany.getId();
            return vm.bitacoraAcciones;

        }

        vm.showDate = function (globalConcept) {
            if (globalConcept.date != null) {
                ExchangeRateBccr.get({
                    fechaInicio: moment(globalConcept.date).utc(-6).format(),
                    fechaFinal: moment(globalConcept.date).utc(-6).format(),
                }, function (result) {
                    vm.tipoCambio = result;
                    vm.Today = globalConcept.date;
                    vm.definirMantenimiento();
                });
            }
        };
        vm.autoConcept = function (globalConcept) {
            String.prototype.capitalize = function () {
                return this.replace(/(?:^|\s)\S/g, function (a) {
                    return a.toUpperCase();
                });
            };
            vm.showDate(globalConcept);
            var dueDate = new Date(globalConcept.date);
            dueDate.setDate(dueDate.getDate() + vm.adminConfig.daysTobeDefaulter - 1);
            globalConcept.dueDate = dueDate;
            globalConcept.concept = "Cuota " + vm.chargeType.description + " " + moment(globalConcept.date).format("MMMM").capitalize() + " " + moment(globalConcept.date).format("YYYY");
        };


        vm.setGlobalAmmount = function (globalConcept) {
            Modal.confirmDialog("¿Está seguro que desea cambiar el monto de la factura de todos los clientes por esta vez?", "Esto no cambiará el monto de cobro mensual definido", function () {
                angular.forEach(vm.houses, function (value, key) {
                    value.cuotas = [];
                    value.cuotas.push({
                        ammount: globalConcept.ammount,
                        globalConcept: vm.globalConceptNumber
                    });
                });
            });
        };
        vm.deleteDue = function (id) {
            Modal.confirmDialog("¿Está seguro que desea eliminar esta columna?", "",
                function () {
                    angular.forEach(vm.globalConcept, function (value, key) {
                        if (value.id == id) {
                            vm.globalConcept.splice(key, 1);
                        }
                    });

                    angular.forEach(vm.houses, function (value, key) {
                        angular.forEach(value.cuotas, function (cuota, key) {
                            if (cuota.globalConcept == id) {
                                value.cuotas.splice(key, 1);
                            }
                        });
                    });

                });
        };
        vm.formatCurrencyToPay = function (due) {
            var finalDue = 0;
            var venta = vm.bccrUse ? parseFloat(vm.tipoCambio.venta) : parseFloat(vm.adminConfig.exchangeRate);
            vm.tipoCambioVenta = venta;
            if (venta == 0) {
                return finalDue;
            }
            if (vm.adminConfig.chargesCreateCurrency != vm.adminConfig.chargesCollectCurrency) {
                if (vm.adminConfig.chargesCreateCurrency == vm.principalCurrency) {
                    finalDue = due / venta;
                }
                if (vm.adminConfig.chargesCreateCurrency == vm.secondCurrency) {
                    finalDue = due * venta;
                }
            } else {
                finalDue = due;
            }
            return finalDue;
        };

        function loadAll() {
            House.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                companyId: globalCompany.getId()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                $('.dating').keydown(function () {
                    return false;
                });
                $('.numbers').keypress(function (tecla) {
                    if (tecla.charCode < 48 || tecla.charCode > 57) return false;
                });
                AdministrationConfiguration.get({
                    companyId: globalCompany.getId()
                }).$promise.then(function (result) {
                    vm.adminConfig = result;
                    vm.adminConfig.exchangeRate = parseFloat(vm.adminConfig.exchangeRate);
                    vm.globalConceptNumber = 0;
                    vm.globalConcept = [{
                        date: "",
                        concept: "",
                        id: vm.globalConceptNumber,
                        datePickerOpenStatus: false
                    }];
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                    angular.forEach(data, function (value, key) {
                        value.cuotas = [];
                        value.cuotas.push({
                            ammount: 0,
                            globalConcept: vm.globalConceptNumber
                        });

                    });
                    vm.houses = data;
                    vm.page = pagingParams.page;
                    if (vm.showBccrUse()) {
                        ExchangeRateBccr.get({
                            fechaInicio: moment(new Date()).format(),
                            fechaFinal: moment(new Date()).format(),
                        }, function (result) {
                            vm.tipoCambio = result;
                            vm.definirMantenimiento();
                        });
                    } else {
                        vm.definirMantenimiento();
                    }
                    vm.isReady = true;
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.saveAdminConfig = function () {
            vm.adminConfig.exchangeRateDate = moment().format();
            vm.formatCurrencyToPay();
            if (vm.adminConfig.id !== null) {
                AdministrationConfiguration.update(vm.adminConfig, function (result) {
                }, function () {
                });
            } else {
                AdministrationConfiguration.save(vm.adminConfig, function () {
                }, function () {
                });
            }
            angular.forEach(vm.houses, function (value, key) {
                value.cuotas = [];
                if (value.due == undefined) {
                    value.cuotas.push({
                        ammount: 0,
                        globalConcept: vm.globalConceptNumber
                    });
                } else {
                    value.cuotas.push({
                        ammount: vm.formatCurrencyToPay(value.due),
                        globalConcept: vm.globalConceptNumber
                    });
                }

            });
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function openCalendar(index) {
            vm.globalConcept[index].datePickerOpenStatus = true;
        }
    }
})();
