(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('load-automatic-payment', {
                parent: 'entity',
                url: '/load-automatic-payment',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/load-automatic-payment/load-automatic-payment.html',
                        controller: 'LoadAutomaticPaymentController',
                        controllerAs: 'vm'
                    }
                }
            })
            .state('load-automatic-payment-banks', {
                            parent: 'entity',
                            url: '/load-automatic-payment-banks',
                            data: {
                                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_ACCOUNTANT']
                            },
                            views: {
                                'content@': {
                                    templateUrl: 'app/entities/load-automatic-payment/load-automatic-payment-banks.html',
                                    controller: 'LoadAutomaticPaymentBanksController',
                                    controllerAs: 'vm'
                                }
                            }
                        });
    }
})();
