(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('HistoricalPositiveBalance', HistoricalPositiveBalance);

    HistoricalPositiveBalance.$inject = ['Modal', 'ActionLogAdmin', '$rootScope', '$state', 'Charge', 'globalCompany', 'Company', 'CommonMethods', 'AlertService', '$scope', 'House'];

    function HistoricalPositiveBalance(Modal, ActionLogAdmin, $rootScope, $state, Charge, globalCompany, Company, CommonMethods, AlertService, $scope, House) {
        var vm = this;
        vm.loadPage = loadPage;
        vm.transition = transition;
        $rootScope.active = "historical-positive-balance";
        vm.loadAll = loadAll;
        vm.month = new Date();
        vm.currentDate = new Date();
        vm.house = -1;
        vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.simplify = true;
        vm.format = 'L';
        vm.exportingExcel = false;
        moment.locale("es");
        vm.consulted = false;

        vm.clearSearchTerm = function () {
            vm.searchTerm = '';
        };

        vm.positiveBalanceClass = function(amount){
            return parseFloat(amount)>0?'greenBalance':'';
        };

        vm.viewLogAction = function () {
            ActionLogAdmin.queryByCompanyAndType({companyId: globalCompany.getId(), type: "3"}, function (logs) {
                if (logs.length != 0) {
                    vm.log = logs[0];
                } else {
                    vm.log = {description: "Actualizado", responsable: "5%"};
                }
            });
        };

        vm.updateReport = function () {
            // ActionLogAdmin.verifyIsReportIsRunning({type: "3"}, function (logs) {
            //     if (logs.length == 0) {
            Charge.updateHistoricalPositiveReport({
                date: moment(vm.initial_time).format(),
                companyId: globalCompany.getId(),
            }, onSuccessUpdateReport, onErrorUpdateReport);
            //     } else {
            //         var log = logs[0];
            //         var logPorcentage = log.responsable.split("%")[0];
            //         Modal.toast("Hay otro reporte actualizándose, por favor espere. " + (100 - logPorcentage) + "% restante.")
            //     }
            // })
        };

        // vm.updateReport();

        function onSuccessUpdateReport(result) {
            vm.log = result;
            vm.loadAll();
            // Modal.toast("Actualización finalizada.");
        }

        function onErrorUpdateReport() {
            Modal.toast("Ocurrio un error");
        }


        vm.viewLogActionReload = function () {
            ActionLogAdmin.queryByCompanyAndType({companyId: globalCompany.getId(), type: "3"}, function (logs) {
                if (logs.length != 0) {
                    vm.log = logs[0];
                    if (vm.log.description == "Actualizado") {
                        vm.loadAll();
                        Modal.toast("Actualización finalizada.");
                    }
                }
            });
        };

        vm.viewLogAction();

        House.getAllHousesClean({
            companyId: globalCompany.getId()
        }, function (result) {
            vm.houses = result;
        });

        vm.searchTerm;
        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        };
        vm.typingSearchTerm = function (ev) {
            ev.stopPropagation();
        };


        vm.locale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(vm.format) : '';
            }
        };
        vm.changeFormat = function () {
            vm.format = 'MMMM';
            vm.hideDate = true;
            // $timeout(function() {
            vm.hideDate = false;
            // });
        };
        vm.createMonth = function () {
            vm.initial_time = new Date(vm.month.getFullYear(), vm.month.getMonth(), 1);
            vm.final_time = new Date(vm.month.getFullYear(), vm.month.getMonth() + 1, 0);
            var houseId = vm.house == -1 ? -1 : vm.house.id;
            vm.fileUrl = "api/charges/historical-positive-balance-file/" + moment(vm.initial_time).format() + "/" + moment(vm.final_time).format() + "/byCompany/" + globalCompany.getId() + "/house/" + houseId;
            vm.changeFormat();
            // vm.updateReport()
        };
        vm.createMonth();

        vm.detailResident = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('owner-detail', {
                id: encryptedId
            });
        };
        vm.exportActions = {
            downloading: false,
            printing: false,
        };


        vm.tableToExcel = function (table) {
            vm.exportingExcel = true;
            setTimeout(function () {
                var uri = 'data:application/vnd.ms-excel;base64,'
                    ,
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
                    , base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)));
                    }
                    , format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        });
                    };
                var workSheetName = vm.companyName + "- REPORTE DE PAGOS ANTICIPADOS - " + moment(vm.month).format("MMMM YYYY");
                if (!table.nodeType) table = document.getElementById(table);
                var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML};
                var a = document.createElement('a');
                a.href = uri + base64(format(template, ctx));
                a.download = workSheetName + '.xls';
                //triggering the function
                a.click();
                vm.exportingExcel = false;
            }, 1);
        };
        // vm.loadAll();
        vm.showYearDefaulter = function () {
            vm.loadDefaulters(vm.yearDefaulter);
        };
        vm.print = function () {
            vm.exportActions.printing = true;
            setTimeout(function () {
                $scope.$apply(function () {
                    vm.exportActions.printing = false;
                });
            }, 7000);
            printJS({
                printable: vm.fileUrl,
                type: 'pdf',
                modalMessage: "Obteniendo reporte de saldos a favor"
            });
        };
        vm.download = function () {
            window.open(vm.fileUrl, '_blank');
        };

        vm.getCategory = function (type) {
            switch (type) {
            case -1:
                return "TODAS";
                break;
            case 1:
                return "MANTENIMIENTO";
                break;
            case 2:
                return "EXTRAORDINARIA";
                break;
            case 3:
                return "ÁREAS COMUNES";
                break;
            case 5:
                return "MULTA";
                break;
            case 6:
                return "CUOTA AGUA";
                break;
            }
        };

        function loadAll() {
            vm.isReady = false;
            vm.consulted = true;
            vm.companyId = globalCompany.getId();
            if (vm.chargeType == 10) {
                vm.filtering = false;
            } else {
                vm.filtering = true;
            }
            vm.chargeTypeSetted = vm.chargeType;
            Charge.findHistoricalPositiveReport({
                initial_time: moment(vm.initial_time).format(),
                final_time: moment(vm.final_time).format(),
                companyId: vm.companyId,
                houseId: vm.house == -1 ? -1 : vm.house.id
            }, onSuccess, onError);


            function onSuccess(data) {
                vm.report = data;
                var housesDesorder = [];
                angular.forEach(vm.report.dueHouses, function (value, key) {
                    housesDesorder.push(value);
                });
                housesDesorder.sort(function(a, b) {
                    return a.clientName.localeCompare(b.clientName, undefined, { numeric: true, sensitivity: 'base' });
                });
                vm.report.dueHouses = housesDesorder;
                Company.get({id: globalCompany.getId()}).$promise.then(function (result) {
                    vm.isReady = true;
                    vm.companyName = result.name;
                });
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
