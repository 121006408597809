(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('NavbarController', NavbarController);
    NavbarController.$inject = ['$mdDialog', 'GateAccess', 'WSGate', 'AditumGate', 'ActivityResident', 'Modal', 'CompanyConfiguration', '$cookies', 'TokenNotifications', 'WSHouse', 'WSResident', 'WSVehicle', 'WSNote', 'WSVisitor', 'WSOfficer', '$timeout', 'CommonMethods', '$state', 'Auth', 'Principal', 'ProfileService', 'LoginService', 'MultiCompany', '$rootScope', '$scope', 'Company', 'MacroCondominium', 'House', '$mdSidenav', '$localStorage', 'globalCompany', 'WSDeleteEntity', 'WSEmergency', 'WSVisitorAuthorizationRequest', 'UserDevice', '$translate'];

    function NavbarController($mdDialog, GateAccess, WSGate, AditumGate, ActivityResident, Modal, CompanyConfiguration, $cookies, TokenNotifications, WSHouse, WSResident, WSVehicle, WSNote, WSVisitor, WSOfficer, $timeout, CommonMethods, $state, Auth, Principal, ProfileService, LoginService, MultiCompany, $rootScope, $scope, Company, MacroCondominium, House, $mdSidenav, $localStorage, globalCompany, WSDeleteEntity, WSEmergency, WSVisitorAuthorizationRequest, UserDevice, translate) {
        var vm = this;
        vm.colors = {
            primary: "#001e2f",
            secondary: "rgb(222 182 110 / 16%)",
            third: "rgb(222 182 110 / 8%)",
            normalColorFont: "rgba(55,71,79,0.99)"
        };

        vm.menu = {menu: []};
        vm.footerMenu = {menu: []};

        $rootScope.colors = vm.colors;
        vm.hasContability = false;
        $rootScope.currentUserImage = null;
        vm.menuResident = [];
        vm.menuFinanzas = {};
        vm.hasControlAccess = false;
        vm.footerMenuRdy = false;
        vm.menuGate = {};
        vm.menuGate.show = false;
        // Paleta de color ADITÚM
        //
        // Rusty Golden: caba9f
        // Papaia Fresh: deb66e
        // Nautical Blue: 001e2f
        //
        // Neutros: Cualquier gama de grises / Blanco o Negro.


        function sendWSGateOpen(gate) {
            Principal.identity().then(function (data) {
                var bitacora = {
                    id: null,
                    concept: "Puerta " + gate.description + " accionada por la filial " + $rootScope.houseSelected.housenumber,
                    type: 30,
                    idResponsable: data.id,
                    category: "Puertas Acceso",
                    houseId: globalCompany.getHouseId(),
                    companyId: globalCompany.getId(),
                    houseNumber: $rootScope.houseSelected.housenumber,
                };
                WSGate.sendActivity(bitacora);
            });
        }

        vm.openGate = function (gate) {
            Modal.confirmDialog("¿Está seguro que desea accionar la puerta?", "" +
                "Quedará un registro cada vez que la accione.", function () {
                    gate.disable = true;
                    AditumGate.openGate({gateId: gate.id}, function (data) {
                        if (data.result) {
                            Modal.toast("Puerta " + gate.description + " está en movimiento.");
                            gate.movement = true;
                            sendWSGateOpen(gate);
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    gate.movement = false;
                                    gate.disable = false;
                                });
                            }, 5000);
                        } else {
                            setTimeout(function () {
                                $scope.$apply(function () {
                                    gate.movement = false;
                                    gate.disable = false;
                                    Modal.toast("No se pudo contactar con la puerta.");
                                });
                            }, 1000);
                        }
                    }, function () {
                    });
                });
        };


        vm.defineAccessDoorButton = function (account) {
            if (account !== null) {
                switch (account.authorities[0]) {
                case "ROLE_OWNER" || "ROLE_USER":
                    if (globalCompany.getHouseId()) {
                        House.get({id: globalCompany.getHouseId()}, function (data) {
                            if (data.allowAditumGate == 1) {
                                if (vm.companyConfig.hasAditumGate && vm.companyConfig.allowBtnOpenDoor) {
                                    GateAccess.queryByCompany({companyId: globalCompany.getId()}, function (data) {
                                        if (data.length > 0) {
                                            vm.menuGate = {
                                                title: translate.instant('aditumApp.sideBar.security.accessDoor'), //"Puertas de acceso",
                                                icon: "https://firebasestorage.googleapis.com/v0/b/aditum-storage.appspot.com/o/aditum%2Fdoor-e4ac1b6781.svg?alt=media&token=663000e1-c2bd-4e5e-8577-75551b5d91a6",
                                                authoritites: "ROLE_USER,ROLE_OWNER",
                                                uisref: "emergency.new",
                                                show: true,
                                                class: "animate__animated  animate__backInLeft"
                                            };
                                            vm.menuGates = data[0].gates;
                                            vm.menuGate.show = true;
                                            vm.accesDoorId = data[0].id;
                                        } else {
                                            vm.menuGate.show = false;
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            }
        };

        vm.indetail = function () {
            return $rootScope.inForm || $rootScope.inDetail;
        };

        vm.notDetail = function () {
            return !$rootScope.inForm && !$rootScope.inDetail;
        };

        function notificationActivityResident(userId) {
            ActivityResident.getNotSeeingByUser({userId: userId, size: 1, page: 0}, function (data) {
                $rootScope.activityResidentNoti = data.length != 0;
            });
        }


        var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
        vm.colorsMenu = {
            mainButton: {
                color: 'color:' + '#37474f',
                backgroundColor: 'background-color:white!important',
            },
            mainButtonActive: {
                color: 'color:' + '#37474f',
                backgroundColor: 'background-color:white!important',
            },
            mainButtonHover: {
                color: 'color:' + vm.colors.primary + '!important',
                backgroundColor: 'background-color:#deb66e!important',
            },
            secondButton: {
                color: 'color:' + vm.colors.normalColorFont + '!important',
                backgroundColor: 'background-color:white!important',
            },
            secondButtonActive: {
                color: 'color:' + vm.colors.primary + '!important',
                backgroundColor: 'background-color:' + vm.colors.secondary + '!important',
            },
            secondButtonHover: {
                color: 'color:' + vm.colors.primary + '!important',
                backgroundColor: 'background-color:' + vm.colors.third + '!important',
            },
            thirdButton: {
                color: 'color:' + vm.colors.normalColorFont + '!important',
                backgroundColor: 'background-color:white!important',
            },
            thirdButtonActive: {
                color: 'color:' + '#deb66e!important',
                backgroundColor: 'background-color:white!important',
            },
            thirdButtonHover: {
                color: 'color:' + vm.colors.primary + '!important',
                backgroundColor: 'background-color:white!important',
            }
        };

        $scope.$on("$destroy", function () {
            vm.menu = [];
        });

        vm.showAdminCompanyLogo = function (companyId) {
            // var show = 0;
            // for (var i = 0; i < $rootScope.adminCompany.condominiuns.length; i++) {
            //     if ($rootScope.adminCompany.condominiuns[i] == companyId) {
            //         show++;
            //     }
            //     return show == 0;
            // }
        };

        vm.showMenuResident = function () {
            if ($localStorage.houseSelected) {
                var role = $localStorage.houseSelected.roleResident;
                if (role == "ROLE_OWNER" || role == "ROLE_TENANT" || role == "ROLE_RESIDENT") {
                    return true;
                }

                return false;
            }
        };

        vm.showContracts = function () {
            if ($rootScope.houseSelected != undefined) {
                if (isOwnerOrRenter($rootScope.houseSelected.roleResident) && globalCompany.getId() > 1) {
                    return {
                        title: translate.instant("aditumApp.sideBar.menu.documents.contracts"),
                        icon: "badge",
                        authoritites: "ROLE_OWNER,ROLE_USER",
                        activeOn: "contract",
                        collapsable: false,
                        uisref: "contract",
                        menuId: "",
                        hover: false,
                        thirdItems: [],
                        showXs: true,
                        showLg: true,
                    };
                }
            } else {
                return {};
            }
        };

        function isOwnerOrRenter(role) {
            return role == "ROLE_OWNER" || role == "ROLE_RENTER";
        }

        vm.showMenuFinanzas = function () {
            if ($rootScope.houseSelected != undefined) {
                var role = $rootScope.houseSelected.roleResident;
            }
            return isOwnerOrRenter(role);
        };

        vm.chargeMenu = function (hasConta) {
            var menu = [
                {
                    title: "Administración",
                    activeOn: "company,condons,admins,recursosHumanos,brands,destinies,dataprogress,macro-condominium",
                    authoritites: "ROLE_ADMIN",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        // {
                        //     title: "Condominios Macro",
                        //     icon: "account_balance",
                        //     authoritites: "ROLE_ADMIN",
                        //     activeOn: "macro-condominium",
                        //     collapsable: false,
                        //     uisref: "macro-condominium",
                        //     menuId: "",
                        //     hover: false,
                        //     thirdItems: [],
                        //     showXs: true,
                        //     showLg: true,
                        //
                        // },
                        {
                            title: "Condominios",
                            icon: "home",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "condons",
                            collapsable: false,
                            uisref: "company",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: "Usuarios",
                            icon: "supervised_user_circle",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "admins",
                            collapsable: false,
                            uisref: "admin-info-by-company",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true

                        },
                        {
                            title: "Cuentas Oficiales",
                            icon: "verified_user",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "officerAccount",
                            collapsable: false,
                            uisref: "officerAccounts",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true

                        },
                        //
                        //
                        //
                        // {
                        //     title: "Recursos humanos",
                        //     icon: "contacts",
                        //     authoritites: "ROLE_ADMIN",
                        //     activeOn: "recursosHumanos",
                        //     collapsable: false,
                        //     uisref: "rh-account",
                        //     menuId: "",
                        //     hover: false,
                        //     thirdItems: [],
                        //     showXs: true,
                        //     showLg: true
                        //
                        // },
                        {
                            title: "Bitácora de Acciones",
                            icon: "chrome_reader_mode",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "bitacoraAcciones",
                            collapsable: false,
                            uisref: "bitacora-acciones",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Soporte",
                            icon: "support_agent",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "soporte",
                            collapsable: false,
                            uisref: "soporte",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Marcas vehículos",
                            icon: "directions_car",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "brands",
                            collapsable: false,
                            uisref: "brand",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true

                        },
                        {
                            title: "Tipos de filiales",
                            icon: "house",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "brands",
                            collapsable: false,
                            uisref: "subsidiary-type",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true

                        },
                        {
                            title: "Categoría de filiales",
                            icon: "house",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "brands",
                            collapsable: false,
                            uisref: "subsidiary-category",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Crear filiales masivo",
                            icon: "house",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "houses-massive",
                            collapsable: false,
                            uisref: "create-all-houses",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Gestión residentes masivo",
                            icon: "face",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "resident-massive",
                            collapsable: false,
                            uisref: "load-automatic-resident",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Crear visitantes masivo",
                            icon: "account_circle",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "load-automatic-visitor",
                            collapsable: false,
                            uisref: "load-automatic-visitor",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Destinos puerta acceso",
                            icon: "store",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "destinies",
                            collapsable: false,
                            uisref: "destinies",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true

                        },
                        // {
                        //     title: "Ingreso de datos",
                        //     icon: "show_chart",
                        //     authoritites: "ROLE_ADMIN",
                        //     activeOn: "dataprogress",
                        //     collapsable: false,
                        //     uisref: "data-progress",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true
                        // },
                    ]
                },
                {
                    title: "DOCUMENTOS LEGALES",
                    activeOn: "",
                    authoritites: "ROLE_ADMIN",
                    showXs: !$rootScope.company.onlyControlAccess,
                    showLg: !$rootScope.company.onlyControlAccess,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Reglamentos",
                            icon: "gavel",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "regulation",
                            collapsable: false,
                            uisref: "regulation",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: "Categorías",
                            icon: "category",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "article-category",
                            collapsable: false,
                            uisref: "article-category",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: "Palabras clave",
                            icon: "vpn_key",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "key-words",
                            collapsable: false,
                            uisref: "key-words",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        // {
                        //     title: "Búsqueda",
                        //     icon: "vpn_key",
                        //     authoritites: "ROLE_ADMIN",
                        //     activeOn: "regulation-search",
                        //     collapsable: false,
                        //     uisref: "regulation-search-tabs.byCategories",
                        //     menuId: "",
                        //     hover: false,
                        //     thirdItems: [],
                        //     showXs: true,
                        //     showLg: true,
                        //
                        // }
                    ]
                },
                {
                    title: "ADITUM JHIPSTER",
                    activeOn: "company,condons,admins,recursosHumanos,brands,destinies,dataprogress",
                    authoritites: "ROLE_ADMIN",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Gestión de usuarios",
                            icon: "supervised_user_circle",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "user-management",
                            collapsable: false,
                            uisref: "user-management",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Tracker",
                            icon: "remove_red_eye",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "tracker",
                            collapsable: false,
                            uisref: "jhi-tracker",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Métricas",
                            icon: "pie_chart",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "metrics",
                            collapsable: false,
                            uisref: "jhi-metrics",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Salud",
                            icon: "sentiment_satisfied_alt",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "health",
                            collapsable: false,
                            uisref: "jhi-health",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Configuración",
                            icon: "build",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "configuration",
                            collapsable: false,
                            uisref: "jhi-configuration",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Auditorías",
                            icon: "people",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "audits",
                            collapsable: false,
                            uisref: "audits",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Logs",
                            icon: "pan_tool",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "logs",
                            collapsable: false,
                            uisref: "logs",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "API",
                            icon: "library_books",
                            authoritites: "ROLE_ADMIN",
                            activeOn: "docs",
                            collapsable: false,
                            uisref: "docs",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true
                        },
                    ]
                },
                {
                    title: "General",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Centro de control",
                            icon: "dashboard",
                            authoritites: "ROLE_MANAGER_MACRO,ROLE_MANAGER",
                            activeOn: "dashboard",
                            collapsable: false,
                            uisref: "dashboard",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Expedientes",
                            icon: "topic",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "house-record",
                            collapsable: false,
                            uisref: "house-records",
                            menuId: "",
                            hover: false,
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess
                        },
                        {
                            title: "Usuarios",
                            icon: "supervised_user_circle",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "resident-all",
                            collapsable: false,
                            uisref: "resident-all",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        // {
                        //     title: "Propietarios",
                        //     icon: "group",
                        //     authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                        //     activeOn: "owner",
                        //     collapsable: false,
                        //     uisref: "owner",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true
                        // },
                        // {
                        //     title: "Inquilinos",
                        //     icon: "group",
                        //     authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                        //     activeOn: "tenant",
                        //     collapsable: false,
                        //     uisref: "tenant",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true
                        // },
                        // {
                        //     title: "Autorizados",
                        //     icon: "group",
                        //     authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                        //     activeOn: "residents",
                        //     collapsable: false,
                        //     uisref: "resident",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true
                        // },
                        {
                            title: "Mascotas",
                            icon: "pets",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "pet",
                            collapsable: false,
                            uisref: "pet",
                            menuId: "",
                            hover: false,
                            showXs: vm.showEjecPresu,
                            showLg: vm.showEjecPresu
                        },
                        {
                            title: "Filiales",
                            icon: "home",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "houses",
                            collapsable: false,
                            uisref: "houses-tabs.house",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Vehículos",
                            icon: "directions_car",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "vehicules",
                            collapsable: false,
                            uisref: "vehicule",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Emergencias",
                            icon: "local_hospital",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "emergency",
                            collapsable: false,
                            uisref: "emergency",
                            menuId: "",
                            hover: false,
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess
                        },
                        // showCondoAdministrationNoControlAccess()
                    ]
                },
                {
                    title: "Comunicación",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Áreas comunes",
                            icon: "local_florist",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "reservationAdministration,reservations,generaCalendar,createReservation,devolutions",
                            collapsable: true,
                            uisref: "",
                            menuId: "areascomunesMenu",
                            hover: false,
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess,
                            thirdItems: [
                                {
                                    title: "Administrar",
                                    icon: "view_agenda",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "reservationAdministration",
                                    collapsable: false,
                                    uisref: "common-area-administration.common-area",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true,
                                },
                                {
                                    title: "Reservaciones",
                                    icon: "view_comfy",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "reservations",
                                    collapsable: false,
                                    uisref: "common-area-administration.common-area-reservations",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true,
                                },
                                {
                                    title: "Ver calendario",
                                    icon: "event_note",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "generaCalendar",
                                    collapsable: false,
                                    uisref: "common-area-administration.general-reservation-calendar",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true,
                                },
                                {
                                    title: "Reservar",
                                    icon: "event",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "createReservation",
                                    collapsable: false,
                                    uisref: "common-area-administration.newReservation",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true,
                                },
                                // {
                                //     title: "Devoluciones",
                                //     icon: "payment",
                                //     authoritites: "ROLE_MANAGER",
                                //     activeOn: "devolutions",
                                //     collapsable: false,
                                //     uisref: "common-area-devolution-administration.pending-devolution",
                                //     menuId: "",
                                //     hover: false,
                                //     showXs: true,
                                //     showLg: true,
                                // },


                            ]
                        },
                        {
                            title: "Noticias",
                            icon: "feed",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "announcements,userNews",
                            collapsable: true,
                            uisref: "",
                            menuId: "noticiasMenu",
                            hover: false,
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess,
                            thirdItems: [
                                {
                                    title: "Ver noticias",
                                    icon: "remove_red_eye",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "userNews",
                                    collapsable: false,
                                    uisref: "announcement-user",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true
                                },
                                {
                                    title: "Administrar noticias",
                                    icon: "view_agenda",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "announcements",
                                    collapsable: false,
                                    uisref: "announcements.announcement",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true
                                },
                            ]
                        },
                        {
                            title: "Gestionar tickets",
                            icon: "record_voice_over",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "complaint",
                            collapsable: false,
                            uisref: "complaint",
                            menuId: "",
                            hover: false,
                            secondaryItems: [],
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Comun. individuales",
                            icon: "forum",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "individual-release",
                            collapsable: false,
                            uisref: "individual-release",
                            menuId: "",
                            hover: false,
                            secondaryItems: [],
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "QuickMessage",
                            icon: "quickreply",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO,ROLE_JD",
                            activeOn: "sended-notifications",
                            collapsable: false,
                            uisref: "notification-sended",
                            menuId: "",
                            hover: false,
                            secondaryItems: [],
                            showXs: !$rootScope.company.onlyControlAccess && globalCompany.getId() != 62,
                            showLg: !$rootScope.company.onlyControlAccess && globalCompany.getId() != 62,
                        },
                        {
                            title: "Notas de Oficiales",
                            icon: "speaker_notes",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO,ROLE_JD",
                            activeOn: "admin-notes",
                            collapsable: false,
                            uisref: "admin-notes",
                            menuId: "",
                            hover: false,
                            secondaryItems: [],
                            showXs: vm.hasControlAccess && globalCompany.getId() != 62,
                            showLg: vm.hasControlAccess && globalCompany.getId() != 62,
                        }
                    ]
                },
                {
                    title: "Finanzas",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER, ROLE_ACCOUNTANT",
                    showXs: true,
                    hasContability: (hasConta && !$rootScope.company.onlyControlAccess) || globalCompany.getId() == 99 || globalCompany.getId() == 100 || globalCompany.getId() == 102,
                    secondaryItems: [
                        {
                            title: "Contabilidad filiales",
                            icon: "dvr",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "houseAdministration",
                            collapsable: false,
                            uisref: "houseAdministration.accountStatus",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Ver egresos",
                            icon: "remove_red_eye",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "egress",
                            collapsable: false,
                            uisref: "egress-tabs.egress",
                            menuId: "",
                            hover: false,
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: false,
                        },
                        {
                            title: "Egresos",
                            icon: "call_received",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "egress,newEgress",
                            collapsable: true,
                            uisref: "",
                            menuId: "egresosMenu",
                            hover: false,
                            showXs: false,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                            thirdItems: [
                                {
                                    title: "Cuentas por pagar",
                                    icon: "receipt",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "egress",
                                    collapsable: false,
                                    uisref: "bills-to-pay",
                                    menuId: "",
                                    hover: false,
                                    showLg: true

                                },
                                {
                                    title: "Capturar egresos",
                                    icon: "payment",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "newEgress",
                                    collapsable: false,
                                    uisref: "egress-tabs.new",
                                    menuId: "",
                                    hover: false,
                                    showXs: false,
                                    showLg: true
                                },
                            ]
                        },
                        {
                            title: "Ingresos",
                            icon: "call_made",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "generatePayment,capturarAdelanto",
                            collapsable: true,
                            uisref: "",
                            menuId: "ingresosMenu",
                            hover: false,
                            showXs: false,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                            thirdItems: [
                                {
                                    title: "Capturar ingreso",
                                    icon: "payment",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "generatePayment",
                                    collapsable: false,
                                    uisref: "generatePayment",
                                    menuId: "",
                                    hover: false,
                                    showXs: false,
                                    showLg: true
                                },
                                // {
                                //     title: "Capturar adelanto",
                                //     icon: "redo",
                                //     authoritites: "ROLE_MANAGER",
                                //     activeOn: "capturarAdelanto",
                                //     collapsable: false,
                                //     uisref: "advancePayment",
                                //     menuId: "",
                                //     hover: false,
                                //     showXs: false,
                                //     showLg: true
                                // },
                                {
                                    title: "Otro ingreso",
                                    icon: "monetization_on",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "otroIngreso",
                                    collapsable: false,
                                    uisref: "otherPayment",
                                    menuId: "",
                                    hover: false,
                                    showXs: false,
                                    showLg: true
                                },
                            ]
                        },
                        {
                            title: "Generar cuotas",
                            icon: "turned_in",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "mensual,extraordinary,individual",
                            collapsable: true,
                            uisref: "",
                            menuId: "cuotasMenu",
                            hover: false,
                            showXs: false,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                            thirdItems: [
                                {
                                    title: "Mensuales",
                                    icon: "remove",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "mensual",
                                    collapsable: false,
                                    uisref: "mensualCharge",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true
                                },
                                {
                                    title: "Extraordinarias",
                                    icon: "remove",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "extraordinary",
                                    collapsable: false,
                                    uisref: "extraordinaryCharge",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true
                                },
                                {
                                    title: "Individual",
                                    icon: "remove",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "individual",
                                    collapsable: false,
                                    uisref: "individualCharge",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true
                                },
                                {
                                    title: "Multa",
                                    icon: "remove",
                                    authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                                    activeOn: "multaCharge",
                                    collapsable: false,
                                    uisref: "multaCharge",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true
                                },

                            ]
                        },
                        {
                            title: "Crear multas / intereses",
                            icon: "currency_exchange",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "generate-penalty",
                            collapsable: false,
                            uisref: "generate-penalty",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Enviar estado de cuenta",
                            icon: "all_inbox",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "sendAccountStatus",
                            collapsable: false,
                            uisref: "send-account-status",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Estados financieros",
                            icon: "import_contacts",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT,ROLE_JD",
                            activeOn: "mensualBillingFile",
                            collapsable: false,
                            uisref: "mensual-billing-file",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Comprobantes de pago",
                            icon: "description",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "paymentProof",
                            collapsable: false,
                            uisref: "paymentProof.pending",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        // {
                        //     title: "Presupuestos",
                        //     icon: "trending_up",
                        //     authoritites: "ROLE_MANAGER",
                        //     activeOn: "presupuestos",
                        //     collapsable: false,
                        //     uisref: "presupuesto",
                        //     menuId: "",
                        //     hover: false,
                        //     thirdItems: [],
                        //     showXs: false,
                        //     showLg: true,
                        // },
                        {
                            title: "Bancos",
                            icon: "account_balance",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "bancos",
                            collapsable: false,
                            uisref: "banco",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Consumo de Agua",
                            icon: "opacity",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "waterConsumption",
                            collapsable: false,
                            uisref: "water-consumption",
                            menuId: "",
                            hover: false,
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess
                        },
                        {
                            title: "Saldo de filiales",
                            icon: "library_books",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "balance",
                            collapsable: false,
                            uisref: "houses-balance",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Cargar Cuotas",
                            icon: "publish",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "load-automatic-charge",
                            collapsable: false,
                            uisref: "load-automatic-charge",
                            menuId: "",
                            hover: false,
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Cargar Pagos Excel",
                            icon: "upload",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "load-automatic-payment",
                            collapsable: false,
                            uisref: "load-automatic-payment",
                            menuId: "",
                            hover: false,
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                        {
                            title: "Cargar Pagos Estado de cuenta",
                            icon: "bank",
                            authoritites: "ROLE_MANAGER,ROLE_ACCOUNTANT",
                            activeOn: "load-automatic-payment-banks",
                            collapsable: false,
                            uisref: "load-automatic-payment-banks",
                            menuId: "",
                            hover: false,
                            showXs: hasConta && !$rootScope.company.onlyControlAccess,
                            showLg: hasConta && !$rootScope.company.onlyControlAccess,
                        },
                    ]
                },
                {
                    title: "ADITUM PROGRESO",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER,ROLE_JD",
                    showXs: !$rootScope.company.onlyControlAccess,
                    showLg: !$rootScope.company.onlyControlAccess,
                    hasContability: true,
                    secondaryItems: [
                        // {
                        //     title: "Reporte de seguimientos",
                        //     icon: "model_training",
                        //     authoritites: "ROLE_MANAGER,ROLE_JD",
                        //     activeOn: "follow-up-report",
                        //     collapsable: false,
                        //     uisref: "follow-up",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: false,
                        //     showLg: true,
                        // },
                        {
                            title: "Lista de tareas ",
                            icon: "post_add",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "tasks",
                            collapsable: false,
                            uisref: "tasks",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Revisiones rutinarias",
                            icon: "assignment_turned_in",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "revisionSemanal",
                            collapsable: false,
                            uisref: "revision",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Configurar revisiones",
                            icon: "build",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "revisionsConfig",
                            collapsable: false,
                            uisref: "revision-config",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,
                        },
                    ]
                },
                {
                    title: "Reportes",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER",
                    showXs: !$rootScope.company.onlyControlAccess,
                    showLg: !$rootScope.company.onlyControlAccess,
                    hasContability: hasConta,
                    secondaryItems: [
                        {
                            title: "Tabla de cobranza",
                            icon: "grid_on",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "collectionTable",
                            collapsable: false,
                            uisref: "collection-table",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: true,

                        },
                        // {
                        //     title: "Flujo efectivo",
                        //     icon: "equalizer",
                        //     authoritites: "ROLE_MANAGER",
                        //     activeOn: "estadoResultados",
                        //     collapsable: false,
                        //     uisref: "resultStates.mensualReport",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true,
                        // },
                        // {
                        //     title: "Ejec. presupuestaria",
                        //     icon: "monetization_on",
                        //     authoritites: "ROLE_MANAGER",
                        //     activeOn: "budgetExecution",
                        //     collapsable: false,
                        //     uisref: "budgetExecution.mensualReport",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true,
                        // },
                        {
                            title: "Reporte de egresos",
                            icon: "keyboard_backspace",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "reporteGastos",
                            collapsable: false,
                            uisref: "egress-report",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Reporte de ingresos",
                            icon: "trending_flat",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "reporteIngresos",
                            collapsable: false,
                            uisref: "payment-report",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Reporte facturación",
                            icon: "money",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "reporteFacturación",
                            collapsable: false,
                            uisref: "billing-report",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Cuentas por pagar",
                            icon: "account_balance_wallet",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "cuentasPorPagar",
                            collapsable: false,
                            uisref: "egress-to-pay",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Cuotas por cobrar",
                            icon: "list",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "reporteCuotasPorPagar",
                            collapsable: false,
                            uisref: "chargesReport",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Morosidad por días",
                            icon: "calendar_view_month",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "delinquencyTable",
                            collapsable: false,
                            uisref: "delinquency-table",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Morosidad histórica",
                            icon: "restore",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "historical-defaulters",
                            collapsable: false,
                            uisref: "historicalDefaulters",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Saldos a favor histórico",
                            icon: "local_atm",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "historical-positive-balance",
                            collapsable: false,
                            uisref: "historicalPositiveBalance",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Bitácora de acciones",
                            icon: "chrome_reader_mode",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "bitacoraAcciones",
                            collapsable: false,
                            uisref: "bitacora-acciones",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                    ]
                },
                {
                    title: "DOCUMENTOS LEGALES",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Reglamentos",
                            icon: "gavel",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "regulation",
                            collapsable: false,
                            uisref: "regulation",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,
                        },
                        // {
                        //     title: "Búsqueda",
                        //     icon: "vpn_key",
                        //     authoritites: "ROLE_MANAGER",
                        //     activeOn: "regulation-search",
                        //     collapsable: false,
                        //     uisref: "regulation-search-tabs.byCategories",
                        //     menuId: "",
                        //     hover: false,
                        //     thirdItems: [],
                        //     showXs: true,
                        //     showLg: true,
                        //
                        // },
                        {
                            title: "Contratos",
                            icon: "description",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "contract",
                            collapsable: false,
                            uisref: "contract",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: "Documentos",
                            icon: "class",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "minutes",
                            collapsable: false,
                            uisref: "minutes",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: "Actas y minutas",
                            icon: "class",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "records",
                            collapsable: false,
                            uisref: "condominium-record",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        }
                    ]
                },
                {
                    title: "Control de acceso",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER_MACRO,ROLE_MANAGER",
                    showXs: true,
                    hasContability: vm.hasControlAccess,
                    secondaryItems: [
                        // showCondoAdministrationContability(),
                        {
                            title: "Mis visitas",
                            icon: "person_pin",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "reportInvitation,residentsInvitedVisitors",
                            collapsable: true,
                            uisref: "",
                            menuId: "administracionVisitasMenu",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess,
                            thirdItems: [
                                {
                                    title: "Reportar visita",
                                    icon: "person_add_alt",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "reportInvitation",
                                    collapsable: false,
                                    uisref: "visitant-invited-user.new",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true,
                                },
                                {
                                    title: "Mis invitados",
                                    icon: "forward_to_inbox",
                                    authoritites: "ROLE_MANAGER",
                                    activeOn: "residentsInvitedVisitors",
                                    collapsable: false,
                                    uisref: "visitant-invited-user",
                                    menuId: "",
                                    hover: false,
                                    showXs: true,
                                    showLg: true,
                                },
                            ]
                        },
                        {
                            title: "Visitantes invitados",
                            icon: "face",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "visitantsAdminView",
                            collapsable: false,
                            uisref: "visitant-invited-admin-view",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Bitácora visitantes",
                            icon: "group_add",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "adminVisitors",
                            collapsable: false,
                            uisref: "visitant-admin",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess
                        },
                        // {
                        //     title: "Seguridad",
                        //     icon: "security",
                        //     authoritites: "ROLE_MANAGER",
                        //     activeOn: "officers,watches",
                        //     collapsable: true,
                        //     uisref: "",
                        //     menuId: "seguridadMenu",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true,
                        //     thirdItems: [
                        //         {
                        //             title: "Oficiales",
                        //             icon: "people_outline",
                        //             authoritites: "ROLE_MANAGER",
                        //             activeOn: "officers",
                        //             collapsable: false,
                        //             uisref: "officer",
                        //             menuId: "",
                        //             hover: false,
                        //             thirdItems: [],
                        //             showXs: true,
                        //             showLg: true
                        //         },
                        //         {
                        //             title: "Turnos",
                        //             icon: "access_time",
                        //             authoritites: "ROLE_MANAGER",
                        //             activeOn: "watches",
                        //             collapsable: false,
                        //             uisref: "turno",
                        //             menuId: "",
                        //             hover: false,
                        //             thirdItems: [],
                        //             showXs: vm.hasWatches,
                        //             showLg: vm.hasWatches
                        //         },
                        //     ]
                        // },

                    ]
                },


                showRounds(),
                // showCondoAdministrationNoContability(),

                {
                    title: "Configuración",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER",
                    showLg: !$rootScope.company.onlyControlAccess,
                    showXs: !$rootScope.company.onlyControlAccess,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "General",
                            icon: "build",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "administrationConfiguration",
                            collapsable: false,
                            uisref: "administration-configuration-detail",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Parqueos",
                            icon: "electric_car",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "parking-slot-configuration-detail",
                            collapsable: false,
                            uisref: "parking-slot-configuration-detail",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess,
                        },
                        {
                            title: "Contactos Admin.",
                            icon: "contact_phone",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "administration-contact",
                            collapsable: false,
                            uisref: "administration-contact",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Junta directiva",
                            icon: "supervised_user_circle",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "jdAccount",
                            collapsable: false,
                            uisref: "junta-directiva-account.new",
                            menuId: "",
                            hover: false,
                            showXs: globalCompany.getId() != 17,
                            showLg: globalCompany.getId() != 17,
                        },
                        {
                            title: "Proveedores",
                            icon: "store_mall_directory",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "proovedores",
                            collapsable: false,
                            uisref: "proveedor",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: hasConta,
                        },
                        {
                            title: "Filiales",
                            icon: "maps_home_work",
                            authoritites: "ROLE_MANAGER",
                            uisref: "configureCharges",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: hasConta,
                        },
                        {
                            title: "Tipos de cuota",
                            icon: "turned_in_not",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "custom-charge-type",
                            collapsable: false,
                            uisref: "custom-charge-type",
                            menuId: "",
                            hover: false,
                            showXs: hasConta,
                            showLg: hasConta,
                        },
                        {
                            title: "Saldos iniciales",
                            icon: "assignment_returned",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "initialBalance",
                            collapsable: false,
                            uisref: "balance-by-account",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: hasConta,
                        },
                        {
                            title: "Cuentas de banco",
                            icon: "account_balance",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "bancoConfiguration",
                            collapsable: false,
                            uisref: "banco-configuration",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: hasConta,
                        },
                        {
                            title: "Categoría de egresos",
                            icon: "call_received",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "egressCategories",
                            collapsable: false,
                            uisref: "egress-category",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: hasConta,
                        },

                    ]
                },
                {
                    title: "General",
                    activeOn: "",
                    authoritites: "ROLE_JD",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Expedientes",
                            icon: "topic",
                            authoritites: "ROLE_JD",
                            activeOn: "house-record",
                            collapsable: false,
                            uisref: "house-records",
                            menuId: "",
                            hover: false,
                            showXs: !$rootScope.company.onlyControlAccess,
                            showLg: !$rootScope.company.onlyControlAccess
                        },
                        {
                            title: "Noticias",
                            icon: "feed",
                            authoritites: "ROLE_JD",
                            activeOn: "userNews",
                            collapsable: false,
                            uisref: "announcement-user",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                            thirdItems: []
                        },
                        {
                            title: "Propietarios",
                            icon: "group",
                            authoritites: "ROLE_JD",
                            activeOn: "owner",
                            collapsable: false,
                            uisref: "owner",
                            menuId: "",
                            hover: false,
                            showXs: globalCompany.getId() != 62,
                            showLg: globalCompany.getId() != 62
                        },
                        {
                            title: "Inquilinos",
                            icon: "group",
                            authoritites: "ROLE_JD",
                            activeOn: "tenant",
                            collapsable: false,
                            uisref: "tenant",
                            menuId: "",
                            hover: false,
                            showXs: globalCompany.getId() != 62,
                            showLg: globalCompany.getId() != 62
                        },
                        {
                            title: "Visitantes",
                            icon: "group_add",
                            authoritites: "ROLE_JD",
                            activeOn: "adminVisitors",
                            collapsable: false,
                            uisref: "visitant-admin",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess && globalCompany.getId() != 62,
                            showLg: vm.hasControlAccess && globalCompany.getId() != 62,
                            thirdItems: []
                        },
                        {
                            title: "Mascotas",
                            icon: "pets",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "pet",
                            collapsable: false,
                            uisref: "pet",
                            menuId: "",
                            hover: false,
                            showXs: vm.showEjecPresu && globalCompany.getId() != 62,
                            showLg: vm.showEjecPresu && globalCompany.getId() != 62
                        },
                        {
                            title: "Vehículos",
                            icon: "directions_car",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "vehicules",
                            collapsable: false,
                            uisref: "vehicule",
                            menuId: "",
                            hover: false,
                            showXs: globalCompany.getId() != 62,
                            showLg: globalCompany.getId() != 62
                        },
                        // {
                        //     title: "Seguridad",
                        //     icon: "security",
                        //     authoritites: "ROLE_JD",
                        //     activeOn: "officers,watches",
                        //     collapsable: true,
                        //     uisref: "",
                        //     menuId: "seguridadMenuJD",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true,
                        //     thirdItems: [
                        //         {
                        //             title: "Oficiales",
                        //             icon: "people_outline",
                        //             authoritites: "ROLE_JD",
                        //             activeOn: "officers",
                        //             collapsable: false,
                        //             uisref: "officer",
                        //             menuId: "",
                        //             hover: false,
                        //             thirdItems: [],
                        //             showXs: true,
                        //             showLg: true
                        //         },
                        //         {
                        //             title: "Turnos",
                        //             icon: "timer",
                        //             authoritites: "ROLE_JD",
                        //             activeOn: "watches",
                        //             collapsable: false,
                        //             uisref: "turno",
                        //             menuId: "",
                        //             hover: false,
                        //             thirdItems: [],
                        //             showXs: vm.hasWatches,
                        //             showLg: vm.hasWatches
                        //         },
                        //     ]
                        // },
                        {
                            title: "Tickets",
                            icon: "record_voice_over",
                            authoritites: "ROLE_JD",
                            activeOn: "complaint",
                            collapsable: false,
                            uisref: "complaint",
                            menuId: "",
                            hover: false,
                            secondaryItems: [],
                            showXs: globalCompany.getId() != 62,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Comun. individuales",
                            icon: "forum",
                            authoritites: "ROLE_JD",
                            activeOn: "individual-release",
                            collapsable: false,
                            uisref: "individual-release",
                            menuId: "",
                            hover: false,
                            secondaryItems: [],
                            showXs: globalCompany.getId() != 62,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Contactos Admin.",
                            icon: "contact_phone",
                            authoritites: "ROLE_JD",
                            activeOn: "administration-contact",
                            collapsable: false,
                            uisref: "administration-contact",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Notas de Oficiales",
                            icon: "speaker_notes",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO,ROLE_JD",
                            activeOn: "admin-notes",
                            collapsable: false,
                            uisref: "admin-notes",
                            menuId: "",
                            hover: false,
                            secondaryItems: [],
                            showXs: vm.hasControlAccess && globalCompany.getId() != 62,
                            showLg: vm.hasControlAccess && globalCompany.getId() != 62,
                        }
                    ]
                },
                {
                    title: "DOCUMENTOS LEGALES",
                    activeOn: "",
                    authoritites: "ROLE_JD",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Reglamentos",
                            icon: "gavel",
                            authoritites: "ROLE_JD",
                            activeOn: "regulation",
                            collapsable: false,
                            uisref: "regulation",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        // {
                        //     title: "Búsqueda",
                        //     icon: "vpn_key",
                        //     authoritites: "ROLE_JD",
                        //     activeOn: "regulation-search",
                        //     collapsable: false,
                        //     uisref: "regulation-search-tabs.byCategories",
                        //     menuId: "",
                        //     hover: false,
                        //     thirdItems: [],
                        //     showXs: true,
                        //     showLg: true,
                        //
                        // },
                        {
                            title: "Contratos",
                            icon: "description",
                            authoritites: "ROLE_JD",
                            activeOn: "contract",
                            collapsable: false,
                            uisref: "contract",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: "Documentos",
                            icon: "class",
                            authoritites: "ROLE_JD",
                            activeOn: "minutes",
                            collapsable: false,
                            uisref: "minutes",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: "Actas condominales",
                            icon: "class",
                            authoritites: "ROLE_JD",
                            activeOn: "records",
                            collapsable: false,
                            uisref: "condominium-record",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        }
                    ]
                },
                {
                    title: "Finanzas",
                    activeOn: "",
                    authoritites: "ROLE_JD",
                    showXs: true,
                    hasContability: hasConta,
                    secondaryItems: [
                        {
                            title: "Contabilidad filiales",
                            icon: "dvr",
                            authoritites: "ROLE_JD",
                            activeOn: "houseAdministration",
                            collapsable: false,
                            uisref: "houseAdministration.accountStatus",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Estados financieros",
                            icon: "import_contacts",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "mensualBillingFile",
                            collapsable: false,
                            uisref: "mensual-billing-file",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Presupuestos",
                            icon: "trending_up",
                            authoritites: "ROLE_JD",
                            activeOn: "presupuestos",
                            collapsable: false,
                            uisref: "presupuesto",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Bancos",
                            icon: "account_balance",
                            authoritites: "ROLE_JD",
                            activeOn: "bancos",
                            collapsable: false,
                            uisref: "banco",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: true,
                        },
                        {
                            title: "Saldo de filiales",
                            icon: "library_books",
                            authoritites: "ROLE_JD",
                            activeOn: "balance",
                            collapsable: false,
                            uisref: "houses-balance",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Proveedores",
                            icon: "store_mall_directory",
                            authoritites: "ROLE_JD",
                            activeOn: "proovedores",
                            collapsable: false,
                            uisref: "proveedor",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                    ]
                },
                {
                    title: "Reportes",
                    activeOn: "",
                    authoritites: "ROLE_JD",
                    showXs: false,
                    hasContability: hasConta,
                    secondaryItems: [
                        {
                            title: "Tabla de cobranza",
                            icon: "grid_on",
                            authoritites: "ROLE_JD",
                            activeOn: "collectionTable",
                            collapsable: false,
                            uisref: "collection-table",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: false,
                            showLg: true,

                        },
                        {
                            title: "Flujo de efectivo",
                            icon: "equalizer",
                            authoritites: "ROLE_JD",
                            activeOn: "estadoResultados",
                            collapsable: false,
                            uisref: "resultStates.mensualReport",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: true,
                        },
                        // {
                        //     title: "Ejec. presupuestaria",
                        //     icon: "monetization_on",
                        //     authoritites: "ROLE_JD",
                        //     activeOn: "budgetExecution",
                        //     collapsable: false,
                        //     uisref: "budgetExecution.mensualReport",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: false,
                        //     showLg: true,
                        // },
                        {
                            title: "Reporte de egresos",
                            icon: "keyboard_backspace",
                            authoritites: "ROLE_JD",
                            activeOn: "reporteGastos",
                            collapsable: false,
                            uisref: "egress-tabs.egress",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Reporte de ingresos",
                            icon: "trending_flat",
                            authoritites: "ROLE_JD",
                            activeOn: "reporteIngresos",
                            collapsable: false,
                            uisref: "payment-report",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Reporte facturación",
                            icon: "money",
                            authoritites: "ROLE_JD",
                            activeOn: "reporteFacturación",
                            collapsable: false,
                            uisref: "billing-report",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: globalCompany.getId() != 62
                        },
                        {
                            title: "Cuentas por pagar",
                            icon: "account_balance_wallet",
                            authoritites: "ROLE_JD",
                            activeOn: "cuentasPorPagar",
                            collapsable: false,
                            uisref: "egress-to-pay",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: true,
                        },
                        {
                            title: "Cuotas por cobrar",
                            icon: "list",
                            authoritites: "ROLE_JD",
                            activeOn: "reporteCuotasPorPagar",
                            collapsable: false,
                            uisref: "chargesReport",
                            menuId: "",
                            hover: false,
                            showXs: false,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Morosidad histórica",
                            icon: "restore",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "historical-defaulters",
                            collapsable: false,
                            uisref: "historicalDefaulters",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: globalCompany.getId() != 62,
                        },
                        {
                            title: "Saldos a favor histórico",
                            icon: "local_atm",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "historical-positive-balance",
                            collapsable: false,
                            uisref: "historicalPositiveBalance",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: globalCompany.getId() != 62,
                        },
                    ]
                },
                {
                    title: "",
                    activeOn: "",
                    authoritites: "ROLE_OWNER,ROLE_USER",
                    showXs: false,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.menu.news'), //"Noticias",
                            icon: "feed",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "userNews",
                            collapsable: false,
                            uisref: "announcement-user",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.finances.title'), //"Finanzas",
                    activeOn: "",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    showXs: false,
                    hasContability: vm.isTenderWithWaterCharge && !$rootScope.company.fullControlAccess,
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.finances.waterDebts'), //"Cuotas de agua",
                            icon: "opacity",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "chargesResidentAccount",
                            collapsable: false,
                            uisref: "waterChargePerHouse-residentAccount",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.finances.paymentProof'), //"Comprobantes de pago",
                            icon: "description",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "paymentProof",
                            collapsable: false,
                            uisref: "paymentProof.pending-user",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        // {
                        //     title: "Pagos",
                        //     icon: "payment",
                        //     authoritites: "ROLE_USER,ROLE_OWNER",
                        //     activeOn: "waterPaymentsResidentAccount",
                        //     collapsable: false,
                        //     uisref: "waterPaymentsPerHouse-residentAccount",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true,
                        // },
                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.commonArea.title'), //"ÁREAS COMUNES",
                    activeOn: "",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    showXs: false,
                    hasContability: vm.bookCommonArea && vm.showMenuResident(),
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.commonArea.reserve'),  //"Reservar",
                            icon: "event",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "reservationDialogResidentView",
                            collapsable: false,
                            uisref: "common-area-reservation-resident-view",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.commonArea.amenities'), //"Amenidades",
                            icon: "local_florist",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "common-area-resident-account,reservationCalendarResidentView",
                            collapsable: false,
                            uisref: "common-area-resident-account",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.commonArea.reservations'), //"Mis reservaciones",
                            icon: "calendar_today",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "allReservationsResidentsView",
                            collapsable: false,
                            uisref: "common-area-all-reservations-resident-view",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                    ]
                },
                {
                    title: "Áreas comunes",
                    activeOn: "",
                    authoritites: "ROLE_JD",
                    showXs: true,
                    hasContability: globalCompany.getId() != 62,
                    secondaryItems: [
                        {
                            title: "Amenidades",
                            icon: "view_agenda",
                            authoritites: "ROLE_JD",
                            activeOn: "reservationAdministration",
                            collapsable: false,
                            uisref: "common-area-administration.common-area",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: "Ver calendario",
                            icon: "event_note",
                            authoritites: "ROLE_JD",
                            activeOn: "generaCalendar",
                            collapsable: false,
                            uisref: "common-area-administration.general-reservation-calendar",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.security.title'), //"Caseta de seguridad",
                    activeOn: "",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    showXs: false,
                    hasContability: vm.showMenuResident() && vm.hasControlAccess,
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.security.reportGuest'), //"Reportar invitado",
                            icon: "person_add_alt",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "reportInvitation",
                            collapsable: false,
                            uisref: "register-visitor-mobile-menu",
                            menuId: "",
                            hover: false,
                            showLg: true,
                            showXs: true
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.security.informOfficer'), //"Informar a oficial",
                            icon: "forward_to_inbox",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "reportHomeService",
                            collapsable: false,
                            uisref: "noteNew",
                            menuId: "",
                            hover: false,
                            showLg: true,
                            showXs: true
                        },
                        // {
                        //     title: "Reportar reunión",
                        //     icon: "group_add",
                        //     authoritites: "ROLE_USER,ROLE_OWNER",
                        //     activeOn: "reportInvitationList",
                        //     collapsable: false,
                        //     uisref: "visitant-invited-user.new-list",
                        //     menuId: "",
                        //     hover: false,
                        //     showLg: true,
                        //     showXs: true
                        // },
                        {
                            title: translate.instant('aditumApp.sideBar.security.authorizations'), //"Autorizaciones caseta",
                            icon: "flaky",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "visitor-authorization-request",
                            collapsable: false,
                            uisref: "visitor-authorization-request",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.security.reportEmergency'), //"Reportar emergencia",
                            icon: "local_fire_department",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "reportemergencyactive",
                            collapsable: false,
                            uisref: "emergency.new",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.security.myGuests'), // "Mis invitados",
                            icon: "hail",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "visitant-invited-user",
                            collapsable: false,
                            uisref: "visitant-invited-user",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.security.notesSent'), //"Mis notas enviadas",
                            icon: "sticky_note_2",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "user-notes",
                            collapsable: false,
                            uisref: "user-notes",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.security.visitors'),
                            icon: "follow_the_signs",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "residentsVisitors",
                            collapsable: false,
                            uisref: "visitant",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.security.securityKey'), //"Clave de seguridad",
                            icon: "vpn_key",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "keysConfiguration",
                            collapsable: false,
                            uisref: "keysConfiguration",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess
                        }
                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.menu.administrative.title'), //"Oficina de administración",
                    activeOn: "",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    showXs: false,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.menu.administrative.observations'), //"Observaciones",
                            icon: "record_voice_over",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "complaint-user",
                            collapsable: false,
                            uisref: "complaint-user",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.administrative.requests'), //"Solicitudes",
                            icon: "forum",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "individual-release-user",
                            collapsable: false,
                            uisref: "individual-release-user",
                            menuId: "",
                            hover: false,
                            showXs: $rootScope.adminCompany.id == 1 && (vm.companyId != 60 && vm.companyId != 91),
                            showLg: $rootScope.adminCompany.id == 1 && (vm.companyId != 60 && vm.companyId != 91)
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.administrative.contacts'), //"Contactos Admin.",
                            icon: "contact_phone",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "administration-contact",
                            collapsable: false,
                            uisref: "administration-contact",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.administrative.routineCheck'), //"Revisiones rutinarias",
                            icon: "assignment_turned_in",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "revisionSemanal",
                            collapsable: false,
                            uisref: "revision",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                    ],
                },
                {
                    title: translate.instant('aditumApp.sideBar.menu.finances.title'), //"Finanzas",
                    activeOn: "",
                    authoritites: "ROLE_OWNER,ROLE_USER",
                    showXs: false,
                    hasContability: hasConta && vm.showMenuFinanzas() && !$rootScope.company.fullControlAccess,
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.menu.finances.accountStatus'), //"Mi estado de cuenta",
                            icon: "account_balance_wallet",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "residentAccountStatus",
                            collapsable: false,
                            uisref: "accountStatus-residentAccount",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.finances.debts'), //"Mis deudas",
                            icon: "request_quote",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "chargesResidentAccount",
                            collapsable: false,
                            uisref: "chargePerHouse-residentAccount",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.finances.payments'), //"Mis pagos",
                            icon: "payments",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "paymentsResidentAccount",
                            collapsable: false,
                            uisref: "paymentsPerHouse-residentAccount",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.finances.paymentProof'), //"Comprobantes de pago",
                            icon: "credit_score",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "paymentProof",
                            collapsable: false,
                            uisref: "paymentProof.pending-user",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.finances.financialState'), //"Estados financieros",
                            icon: "auto_stories",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "mensualBillingFile",
                            collapsable: false,
                            uisref: "mensual-billing-file",
                            menuId: "",
                            hover: false,
                            hasContability: hasConta,
                            thirdItems: [],
                            showXs: vm.showEstadoResultados,
                            showLg: vm.showEstadoResultados,
                        },
                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.menu.myHouse.title'), //"ADMINISTRAR MI FILIAL",
                    activeOn: "",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    showXs: true,
                    hasContability: !vm.showMenuResident(),
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.menu.myHouse.tenants'), //"Inquilinos",
                            icon: "group",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "tenant",
                            collapsable: false,
                            uisref: "tenant",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },


                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.menu.myHouse.title'), //"ADMINISTRAR MI FILIAL",
                    activeOn: "",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    showXs: true,
                    hasContability: vm.showMenuResident(),
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.menu.myHouse.users'), //"Usuarios",
                            icon: "group",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "residentsHouses",
                            collapsable: false,
                            uisref: "residentByHouse",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.myHouse.pets'), //"Mascotas",
                            icon: "pets",
                            authoritites: "ROLE_USER,ROLE_OWNER,ROLE_TENANT",
                            activeOn: "pet",
                            collapsable: false,
                            uisref: "pet",
                            menuId: "",
                            hover: false,
                            showXs: vm.showEjecPresu,
                            showLg: vm.showEjecPresu
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.myHouse.vehicles'), //"Vehículos",
                            icon: "directions_car",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "vehiculesHouses",
                            collapsable: false,
                            uisref: "vehiculeByHouse",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.menu.documents.title'), //"Documentación",
                    activeOn: "",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    showXs: false,
                    hasContability: true,
                    secondaryItems: [

                        {
                            title: translate.instant('aditumApp.sideBar.menu.documents.rules'), //"Reglamentos",
                            icon: "gavel",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "regulation",
                            collapsable: false,
                            uisref: "regulation",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.documents.others'), //"Otros",
                            icon: "description",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "minutes",
                            collapsable: false,
                            uisref: "minutes",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        // {
                        //     title: "Búsqueda",
                        //     icon: "vpn_key",
                        //     authoritites: "ROLE_USER,ROLE_OWNER",
                        //     activeOn: "regulation-search",
                        //     collapsable: false,
                        //     uisref: "regulation-search-tabs.byCategories",
                        //     menuId: "",
                        //     hover: false,
                        //     thirdItems: [],
                        //     showXs: true,
                        //     showLg: true,
                        //
                        // },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.documents.minutes'), //"Actas y minutas",
                            icon: "article",
                            authoritites: "ROLE_USER,ROLE_OWNER",
                            activeOn: "records",
                            collapsable: false,
                            uisref: "condominium-record",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,

                        },
                        vm.showContracts(),
                    ]
                },
                {
                    title: "Macro condominio",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER_MACRO",
                    showXs: false,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Usuarios",
                            icon: "supervised_user_circle",
                            authoritites: "ROLE_MANAGER_MACRO",
                            activeOn: "adminsByCompanyMacro",
                            collapsable: false,
                            uisref: "admin-info-by-company",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                            thirdItems: []
                        },
                        {
                            title: "Bitácora visitantes",
                            icon: "group_add",
                            authoritites: "ROLE_MANAGER_MACRO",
                            activeOn: "adminMacroVisitors",
                            collapsable: false,
                            uisref: "macro-visit",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                            thirdItems: []
                        },

                    ]
                },
                {
                    title: translate.instant('aditumApp.sideBar.menu.support.title'), //"Soporte",
                    icon: "support_agent",
                    authoritites: "ROLE_RH,ROLE_MANAGER,ROLE_USER,ROLE_OWNER,ROLE_JD,ROLE_MANAGER_MACRO",
                    activeOn: "soporte",
                    collapsable: false,
                    uisref: "soporte",
                    menuId: "",
                    hover: false,
                    showXs: true,
                    showLg: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: translate.instant('aditumApp.sideBar.menu.support.tutorials'), //"Tutoriales",
                            icon: "book_online",
                            authoritites: "ROLE_USER,ROLE_OWNER,",
                            activeOn: "tutoriales",
                            collapsable: false,
                            link: "https://www.youtube.com/watch?v=LxNA7x9v2Z0&list=PLo_jkRilCBN1eBlYsS_wzKFEq5LnpI8f6",
                            uisref: "https://www.youtube.com/watch?v=LxNA7x9v2Z0&list=PLo_jkRilCBN1eBlYsS_wzKFEq5LnpI8f6",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                            secondItems: []
                        },
                        {
                            title: translate.instant('aditumApp.sideBar.menu.support.support'), //"Soporte",
                            icon: "support_agent",
                            authoritites: "ROLE_RH,ROLE_MANAGER,ROLE_USER,ROLE_OWNER,ROLE_JD,ROLE_MANAGER_MACRO",
                            activeOn: "soporte-user",
                            collapsable: false,
                            uisref: "soporte-user",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true,
                            secondItems: []
                        },
                    ]
                },
            ];
            showTheOneThatsActive();
            var footerMenu = [
                {
                    title: translate.instant('aditumApp.footerMenu.news'), // ""Noticias",
                    icon: "feed",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    activeOn: "userNews",
                    uisref: "announcement-user",
                    show: true,
                },
                {
                    title: translate.instant('aditumApp.footerMenu.reservations'),  // "Reservas",
                    icon: "event_available",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    activeOn: "common-area-administration-mobile-menu,allReservationsResidentsView,reservationDialogResidentView,common-area-resident-account,reservationCalendarResidentView,common-area-all-reservations-resident-view",
                    uisref: "common-area-administration-mobile-menu",
                    show: vm.bookCommonArea && vm.showMenuResident(),
                },
                {
                    title: translate.instant('aditumApp.footerMenu.security'), //"Seguridad",
                    icon: "admin_panel_settings",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    activeOn: "user-notes,visitant-invited-user,security-mobile-menu,reportHomeService,visitant-invited-user.new-list,reportemergencyactive,visitant-invited-user,residentsVisitors",
                    uisref: "security-mobile-menu",
                    show: vm.showMenuResident() && vm.hasControlAccess,
                },
                {
                    title: translate.instant('aditumApp.footerMenu.finances'), //"Finanzas",
                    icon: "savings",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    activeOn: "finances-mobile-menu,residentAccountStatus,mensualBillingFile,paymentProof,paymentsResidentAccount,chargesResidentAccount",
                    uisref: "finances-mobile-menu",
                    show: (hasConta && !$rootScope.company.fullControlAccess) || globalCompany.getId() == 99 || globalCompany.getId() == 100 || globalCompany.getId() == 102,
                },
                {
                    title: translate.instant('aditumApp.footerMenu.administration'), //"Adminis.",
                    icon: "domain",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    activeOn: "administrative-mobile-menu,complaint-user,revisionSemanal,individual-release-user,documentation-mobile-menu,contract,minutes,regulation,records",
                    uisref: "administrative-mobile-menu",
                    show: true,
                },
                {
                    title: translate.instant('aditumApp.footerMenu.home'),//"Inicio",
                    icon: "roofing",
                    authoritites: "ROLE_USER,ROLE_OWNER",
                    activeOn: "home-mobile-menu",
                    uisref: "home-mobile-menu",
                    show: true,
                },
            ];
            addOrUpdateMenu(menu);
            addOrUpdateMenuFooter(footerMenu);
            showTheOneThatsActive();
            vm.footerMenuRdy = true;
        };

        function addOrUpdateMenu(menu) {
            menu = {companyId: globalCompany.getId(), menu: menu};
            if (!$localStorage.menus) {
                $localStorage.menus = [];
            }
            // Obtener el arreglo actual de menus desde $localStorage
            var menus = $localStorage.menus || [];

            // Buscar si ya existe un menú con el mismo companyId
            var index = -1;
            for (var i = 0; i < menus.length; i++) {
                if (menus[i].companyId === menu.companyId) {
                    index = i;
                    break;
                }
            }

            if (index !== -1) {
                // Si el menú ya existe, reemplazarlo en el arreglo
                menus[index] = menu;
            } else {
                // Si el menú no existe, agregarlo al arreglo
                menus.push(menu);
            }
            // Guardar el arreglo actualizado en $localStorage
            $localStorage.menus = menus;
            vm.menu = menu;
        }

        function addOrUpdateMenuFooter(menu) {
            menu = {companyId: globalCompany.getId(), menu: menu};
            if (!$localStorage.footerMenu) {
                $localStorage.footerMenu = [];
            }
            // Obtener el arreglo actual de menus desde $localStorage
            var menus = $localStorage.footerMenu || [];

            // Buscar si ya existe un menú con el mismo companyId
            var index = -1;
            for (var i = 0; i < menus.length; i++) {
                if (menus[i].companyId === menu.companyId) {
                    index = i;
                    break;
                }
            }

            if (index !== -1) {
                // Si el menú ya existe, reemplazarlo en el arreglo
                menus[index] = menu;
            } else {
                // Si el menú no existe, agregarlo al arreglo
                menus.push(menu);
            }
            // Guardar el arreglo actualizado en $localStorage
            $localStorage.footerMenu = menus;
            vm.footerMenu = menu;
        }

        function searchMenuByCompanyId(companyId) {
            // Obtener el arreglo actual de menus desde $localStorage
            var menus = $localStorage.menus || [];

            // Buscar si existe un menú con el mismo companyId
            for (var i = 0; i < menus.length; i++) {
                if (menus[i].companyId === companyId) {
                    return menus[i]; // Retorna el menú encontrado
                }
            }

            return null; // Retorna null si no se encontró ningún menú con el companyId
        }

        function searchFooterMenuByCompanyId(companyId) {
            // Obtener el arreglo actual de menus desde $localStorage
            var menus = $localStorage.footerMenu || [];

            // Buscar si existe un menú con el mismo companyId
            for (var i = 0; i < menus.length; i++) {
                if (menus[i].companyId === companyId) {
                    return menus[i]; // Retorna el menú encontrado
                }
            }

            return null; // Retorna null si no se encontró ningún menú con el companyId
        }

        vm.loadCompanyConfig = function () {
            if ($state.current.name !== "finishReset") {
                vm.hasContability = false;
                vm.menuGate = {};
                vm.menuGate.show = false;
                vm.menu = searchMenuByCompanyId(globalCompany.getId());
                vm.footerMenu = searchFooterMenuByCompanyId(globalCompany.getId());
                if (vm.menu || vm.footerMenu) {
                    vm.loadedMenu = true;
                    $rootScope.loadedMenu = true;
                }
                if ($localStorage.companyUser) {
                    $rootScope.companyUser = $localStorage.companyUser;
                }
                Principal.identity().then(function (account) {
                    vm.fcmToken = $cookies.get("FCM_TOKEN");
                    if (vm.fcmToken != undefined) {
                        TokenNotifications.login({userId: account.id, token: vm.fcmToken}, function () {
                        });
                    }
                    vm.account = account;
                    MultiCompany.getCurrentUserCompany().then(function (data) {
                        vm.multiCompanyData = data;
                        Company.get({id: parseInt(globalCompany.getId())}, function (condo) {
                            vm.condo = condo;
                            vm.company = condo;
                            $rootScope.company = condo;
                            if (condo.active == 0 || data.enable == 0) {
                                logout();
                            }

                            var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                            vm.hasWatches = false;
                            vm.showEstadoResultados = companyConfig.showEstadoResultados;
                            vm.showEjecPresu = companyConfig.showEjecPresu;
                            vm.bookCommonArea = companyConfig.bookCommonArea;
                            vm.hasRounds = companyConfig.hasRounds;
                            vm.hasControlAccess = companyConfig.hasControlAccess;
                            vm.isTenderWithWaterCharge = false;
                            $rootScope.currency = companyConfig.currency;
                            $rootScope.secondCurrency = companyConfig.secondCurrency;
                            if (companyConfig == "admin") {
                                vm.hasContability = false;
                            } else {
                                if (companyConfig.hasContability == 1) {
                                    vm.hasContability = true;
                                    if (companyConfig.tendersWatchWC && data.type === 4) {
                                        vm.isTenderWithWaterCharge = true;
                                    }
                                } else {
                                    vm.hasContability = false;
                                }
                            }
                            $rootScope.companyUser = data;
                            CompanyConfiguration.get({id: globalCompany.getId()}, function (companyConfig) {
                                vm.chargeMenu(companyConfig.hasContability);
                                vm.loadedMenu = true;
                                vm.companyConfig = companyConfig;
                                $rootScope.currency = data.currency;
                                $rootScope.secondCurrency = data.secondCurrency;
                                $rootScope.loadedMenu = true;
                                vm.defineAccessDoorButton(account);
                                vm.getAcount();
                            });
                        });
                    });
                });
            }
        };

        vm.loadCompanyConfig();

        vm.showSecondItem = function (secondItem) {
            var secondItemsToHideIfHasNoContability = ["Devoluciones", "Ejec. presupuestaria", 'Flujo de efectivo'];
            var items = secondItemsToHideIfHasNoContability;
            var ocultar = 0;
            for (var i = 0; i < items.length; i++) {
                if (secondItem != null) {
                    if (secondItem.title == items[i]) {
                        ocultar++;
                    }
                }
            }
            if (ocultar > 0 && !vm.hasContability) {
                return false;
            } else {
                return true;
            }
        };
        if ($rootScope.companyConfigsLoaded == true) {
            vm.loadCompanyConfig();
        }

        vm.loadedMenu = false;
        $scope.$watch(function () {
            return $rootScope.companyConfigsLoaded;
        }, function (newCodes, oldCodes) {
            if ($rootScope.companyConfigsLoaded == true && vm.loadedMenu == false) {
                vm.loadCompanyConfig();
            }
        });

        vm.defineStyleSecondButton = function (item) {
            if (item != null) {
                if (item.hover) {
                    return vm.colorsMenu.secondButtonHover;
                }
                if (this.defineActive(item) == true) {
                    return vm.colorsMenu.secondButtonActive;
                } else {
                    return vm.colorsMenu.secondButton;
                }
            } else {
                return "";
            }
        };
        vm.defineStyleThirdButton = function (item) {
            if (item.hover) {
                return vm.colorsMenu.thirdButtonHover;
            }
            if (this.defineActive(item) == true) {
                return vm.colorsMenu.thirdButtonActive;
            } else {
                return vm.colorsMenu.thirdButton;
            }
        };
        vm.defineStyleMainButton = function (item) {
            if (item.hover) {
                return vm.colorsMenu.mainButtonHover;
            }
            if (this.defineActive(item) == true) {
                return vm.colorsMenu.mainButtonActive;
            } else {
                return vm.colorsMenu.mainButton;
            }
        };
        vm.defineActive = function (item) {
            if (item.activeOn) {
                var items = item.activeOn.split(",");
                var count = 0;
                for (var i = 0; i < items.length; i++) {
                    if ($rootScope.active == items[i]) {
                        count++;
                    }
                }
                if (count > 0) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        vm.go = function (item) {
            $rootScope.toggleLeft();
            if (item.link != undefined) {
                window.open(item.link);
            } else {
                $state.go(item.uisref);
            }
        };

        vm.collapseAllOther = function (item, fatherItem) {
            if (fatherItem != null) {
                $(".collapse:not(#" + item.menuId + ",#" + fatherItem.menuId + ")").collapse('hide');
            } else {
                $(".collapse:not(#" + item.menuId + ")").collapse('hide');
            }
        };


        function showTheOneThatsActive() {
            if (vm.menu != undefined && vm.menu.menu != undefined) {
                for (var i = 0; i < vm.menu.menu.length; i++) {
                    if (vm.menu[i]) {
                        if (vm.menu[i].secondaryItems != null) {
                            for (var j = 0; j < vm.menu[i].secondaryItems.length; j++) {
                                var secondaryItem = vm.menu[i].secondaryItems[j];
                                if (secondaryItem != null) {
                                    if (secondaryItem.collapsable) {
                                        for (var k = 0; k < secondaryItem.thirdItems.length; k++) {
                                            if (secondaryItem.thirdItems[k].activeOn.includes($rootScope.active)) {
                                                $("#" + secondaryItem.menuId).collapse('show');
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            setTimeout(function () {
                vm.loadedMenu = true;
            }, 400);
        }


        $rootScope.isAuthenticated = Principal.isAuthenticated;
        vm.isAuthenticated = Principal.isAuthenticated;
        $rootScope.toggleLeft = buildToggler('left');


        function buildToggler(componentId) {
            return function () {
                $mdSidenav(componentId).toggle();
            };
        }


        function showRounds() {
            if (vm.hasRounds == true) {
                return {
                    title: "ADITUM RONDAS",
                    activeOn: "",
                    authoritites: "ROLE_MANAGER,ROLE_JD",
                    showXs: true,
                    hasContability: true,
                    secondaryItems: [
                        {
                            title: "Consultar rondas",
                            icon: "directions_walk",
                            authoritites: "ROLE_MANAGER,ROLE_JD",
                            activeOn: "rounds,round-detail",
                            collapsable: false,
                            uisref: "rounds",
                            menuId: "",
                            hover: false,
                            thirdItems: [],
                            showXs: true,
                            showLg: true,
                        },
                    ]
                };
            } else {
                return {};
            }
        }

        function showCondoAdministrationNoControlAccess() {
            if (vm.hasContability === true && !vm.hasControlAccess) {
                return {
                    title: "Administración",
                    icon: "location_city",
                    authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                    activeOn: "residents,vehicules,adminVisitors,bitacoraAcciones",
                    collapsable: true,
                    uisref: "",
                    menuId: "administracionMenu",
                    hover: false,
                    showXs: true,
                    showLg: true,
                    thirdItems: [


                        {
                            title: "Vehículos",
                            icon: "directions_car",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "vehicules",
                            collapsable: false,
                            uisref: "vehicule",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true

                        },
                        {
                            title: "Visitantes",
                            icon: "group_add",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "adminVisitors",
                            collapsable: false,
                            uisref: "visitant-admin",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess
                        },
                        // {
                        //     title: "Bitácora de acciones",
                        //     icon: "chrome_reader_mode",
                        //     authoritites: "ROLE_MANAGER",
                        //     activeOn: "bitacoraAcciones",
                        //     collapsable: false,
                        //     uisref: "bitacora-acciones",
                        //     menuId: "",
                        //     hover: false,
                        //     showXs: true,
                        //     showLg: true
                        // },
                    ]
                };
            } else {
                return null;
            }
        }

        function showCondoAdministrationContability() {
            if (vm.hasContability === true) {
                return {
                    title: "Administración",
                    icon: "location_city",
                    authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                    activeOn: "residents,vehicules,adminVisitors",
                    collapsable: true,
                    uisref: "",
                    menuId: "administracionMenu",
                    hover: false,
                    showXs: true,
                    showLg: true,
                    thirdItems: [
                        {
                            title: "Usuarios",
                            icon: "group",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "residents",
                            collapsable: false,
                            uisref: "resident",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Inquilinos",
                            icon: "group",
                            authoritites: "ROLE_MANAGER,ROLE_MANAGER_MACRO",
                            activeOn: "tenant",
                            collapsable: false,
                            uisref: "tenant",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Vehículos",
                            icon: "directions_car",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "vehicules",
                            collapsable: false,
                            uisref: "vehicule",
                            menuId: "",
                            hover: false,
                            showXs: true,
                            showLg: true
                        },
                        {
                            title: "Visitantes",
                            icon: "group_add",
                            authoritites: "ROLE_MANAGER",
                            activeOn: "adminVisitors",
                            collapsable: false,
                            uisref: "visitant-admin",
                            menuId: "",
                            hover: false,
                            showXs: vm.hasControlAccess,
                            showLg: vm.hasControlAccess
                        },

                    ]
                };
            } else {
                return null;
            }
        }

        function unsubscribe() {
            WSDeleteEntity.unsubscribe(globalCompany.getId());
            WSEmergency.unsubscribe(globalCompany.getId());
            WSHouse.unsubscribe(globalCompany.getId());
            WSResident.unsubscribe(globalCompany.getId());
            WSVehicle.unsubscribe(globalCompany.getId());
            WSNote.unsubscribe(globalCompany.getId());
            WSVisitor.unsubscribe(globalCompany.getId());
            WSOfficer.unsubscribe(globalCompany.getId());
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            switch (globalCompany.getUserRole()) {
            case "ROLE_OFFICER":
                // $timeout.cancel($rootScope.timerAd);
                unsubscribe();
                break;
            case "ROLE_OFFICER_MACRO":
                // $timeout.cancel($rootScope.timerAd);
                unsubscribe();
                break;
            }
            $localStorage.houseSelected = undefined;
            $localStorage.companyUser = undefined;
            $localStorage.menu = undefined;
            $localStorage.footerMenu = undefined;
            $localStorage.menu1HomeMobile = undefined;
            $localStorage.menu2HomeMobile = undefined;
            $localStorage.bankGTIPayment = undefined;
            $localStorage.menuGates = undefined;
            $rootScope.companyUser = undefined;
            $state.go('home');
            $rootScope.menu = false;
            $rootScope.companyId = undefined;
            $localStorage.companyName = undefined;
            $rootScope.companyConfigsLoaded = false;
            $rootScope.showLogin = true;
            $rootScope.inicieSesion = false;
        }

        if ($rootScope.inicieSesion == undefined) {
            $rootScope.inicieSesion = true;
        }
        // $rootScope.$on('$stateChangeStart',
        //     function (event, toState, toParams, fromState, fromParams) {
        //         MultiCompany.getCurrentUserCompany().then(function (data) {
        //             if (data != undefined) {
        //                 if (data.enable == 0 || data.enabled == 0) {
        //                     logout();
        //                 }
        //             }
        //         });
        //     });
        vm.annoActual = moment(new Date()).format("YYYY");
        vm.editMyInfoAsManager = function () {
            $state.go('admin-info-edit');
        };
        vm.editMyInfoAsUser = function () {
            var encryptedId = CommonMethods.encryptIdUrl($rootScope.companyUser.id);
            $state.go('owner.edit', {
                id: encryptedId
            });
        };
        angular.element(document).ready(function () {
            $('body').addClass("gray");
        });
        vm.viewWatch = function () {
            var encryptedId = CommonMethods.encryptIdUrl(globalCompany.getId());
            $state.go('turno', {companyId: encryptedId});
        };

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.login = login;
        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;
        vm.isAdmin = false;

        vm.showFact = function () {
            var condo = vm.condo;
            var direction = condo.direction ? condo.direction : "No definida";
            Modal.customDialog("<md-dialog>" +
                "<md-dialog-content class='md-dialog-content text-center'>" +
                "<h3 class='md-title'>Datos Facturación " + condo.name + "</h3>" +
                "<div class='md-dialog-content-body'>" +
                "<p>Razón social: <span style='font-size: 20px'>" + condo.legalName + "</span></p>" +
                "<p>Cédula: <span style='font-size: 20px'>" + condo.legalIdentification + "</span></p>" +
                "<p>Correo: <span style='font-size: 20px'>" + condo.email + "</span></p>" +
                "<p>Teléfono: <span style='font-size: 20px'>" + condo.phoneNumber + "</span></p>" +
                "<p>Dirección: <span style='font-size: 15px'>" + direction + "</span></p>" +
                "</div>" +
                "</md-dialog-content>" +
                "</md-dialog>");
        };


        vm.getAcount = function () {
            var account = vm.account;
            $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");
            $localStorage.userType = CommonMethods.encryptIdUrl(-1);
            if (!account.activated) {
                logout();
            }
            if (account !== null) {
                $rootScope.account = account;
                switch (account.authorities[0]) {
                case "ROLE_ADMIN":
                    vm.contextLiving = "Dios de Aditum";
                    $rootScope.contextLiving = vm.contextLiving;
                    $rootScope.currentUserImage = null;
                    $rootScope.hideFilial = true;
                    vm.loadedMenu = true;
                    $rootScope.loadedMenu = true;
                    vm.chargeMenu(false);
                    break;
                case "ROLE_MANAGER":
                    var data = vm.multiCompanyData;
                    // notificationActivityResident(account.id);
                    if ($localStorage.companyId == undefined) {
                        $rootScope.companyUser = data;
                        $rootScope.companyUser.companyId = data.companies[0].id;
                        CommonMethods.setCompanyId(data.companies[0].id);
                    }
                    $localStorage.userId = CommonMethods.encryptIdUrl(data.id);
                    $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_MANAGER");
                    if (data.identificationnumber != undefined || data.identificationnumber != null || data.identificationnumber != "") {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl(data.identificationnumber);
                    } else {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");
                    }
                    var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                    $rootScope.currency = companyConfig.currency;
                    $rootScope.secondCurrency = companyConfig.secondCurrency;
                    var condo = vm.condo;
                    vm.contextLiving = condo.name;
                    vm.isAdmin = true;
                    $rootScope.companyName = condo.name;
                    $rootScope.contextLiving = vm.contextLiving;
                    vm.company = condo;
                    $rootScope.company = condo;
                    $rootScope.currentUserImage = data.image_url;
                    $rootScope.companyConfigsLoaded = true;
                    if (data.enabled == 0) {
                        logout();
                    }
                    $rootScope.hideFilial = true;
                    break;
                case "ROLE_ACCOUNTANT":
                    var data = vm.multiCompanyData;
                    // if ($localStorage.companyId == undefined) {
                    //     $rootScope.companyUser = data;
                    //     $rootScope.companyUser.companyId = data.companies[0].id;
                    //     CommonMethods.setCompanyId(data.companies[0].id);
                    // }
                    // $localStorage.userId = CommonMethods.encryptIdUrl(data.id);
                    // $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_ACCOUNTANT");
                    // if (data.identificationnumber != undefined || data.identificationnumber != null || data.identificationnumber != "") {
                    //     $localStorage.userIdNumber = CommonMethods.encryptIdUrl(data.identificationnumber);
                    // } else {
                    //     $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");
                    // }
                    $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_ACCOUNTANT");
                    var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                    $rootScope.currency = companyConfig.currency;
                    $rootScope.secondCurrency = companyConfig.secondCurrency;
                    var condo = vm.condo;
                    vm.contextLiving = condo.name;
                    vm.isAdmin = true;
                    $rootScope.companyName = condo.name;
                    $rootScope.contextLiving = vm.contextLiving;
                    vm.company = condo;
                    $rootScope.company = condo;
                    // $rootScope.currentUserImage = data.image_url;
                    // $rootScope.companyConfigsLoaded = true;
                    if (data.enabled == 0) {
                        logout();
                    }
                    $rootScope.hideFilial = true;
                    break;
                case "ROLE_MANAGER_MACRO":
                    var data = vm.multiCompanyData;
                    if ($localStorage.macroCompanyId == undefined) {
                        $rootScope.companyUser = data;
                        $rootScope.companyUser.companyId = data.macroCondominiumId;
                        $localStorage.macroCompanyId = CommonMethods.encryptIdUrl(data.macroCondominiumId);
                    }
                    $localStorage.userId = CommonMethods.encryptIdUrl(data.id);
                    $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_MANAGER_MACRO");
                    if (data.identificationnumber != undefined || data.identificationnumber != null || data.identificationnumber != "") {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl(data.identificationnumber);
                    } else {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");
                    }
                    var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                    $rootScope.currency = companyConfig.currency;
                    $rootScope.secondCurrency = companyConfig.secondCurrency;
                    MacroCondominium.get({id: data.macroCondominiumId}, function (macroCondo) {
                        vm.contextLiving = macroCondo.name;
                        $rootScope.companyName = macroCondo.name;
                        $rootScope.contextLiving = vm.contextLiving;
                        $rootScope.currentUserImage = data.imageUrl;
                        $rootScope.companyUser.companies = macroCondo.companies;
                        if (data.enabled == 0) {
                            logout();
                        }
                    });
                    $rootScope.hideFilial = true;
                    break;
                case "ROLE_OFFICER_MACRO":
                    MultiCompany.getCurrentUserCompany().then(function (data) {
                        $rootScope.companyUser = data;
                        $localStorage.companyId = CommonMethods.encryptIdUrl(data.macroCondominiumId);
                        $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_OFFICER_MACRO");
                        if (data != null) {
                            vm.contextLiving = $rootScope.companyUser.name;
                            $rootScope.contextLiving = vm.contextLiving;
                            $rootScope.currentUserImage = null;
                        }
                        MacroCondominium.get({id: parseInt(globalCompany.getId())}, function (condo) {
                            if (!condo.enabled || !data.enabled) {
                                logout();
                            }
                        });
                        $rootScope.hideFilial = true;
                    });
                    break;
                case "ROLE_OFFICER":
                    var data = vm.multiCompanyData;
                    $rootScope.companyUser = data;
                    $localStorage.companyId = CommonMethods.encryptIdUrl(data.companyId);
                    $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_OFFICER");
                    if (data != null) {
                        vm.contextLiving = $rootScope.companyUser.name;
                        $rootScope.contextLiving = vm.contextLiving;
                        $rootScope.currentUserImage = null;
                    }
                    Company.get({id: parseInt(globalCompany.getId())}, function (condo) {
                        var condo = vm.condo;
                        vm.contextLiving = condo.name;
                        $rootScope.contextLiving = vm.contextLiving;
                        vm.company = condo;
                        if (condo.active == 0 || data.enable == 0) {
                            logout();
                        }
                    });
                    $rootScope.hideFilial = true;
                    break;
                case "ROLE_USER":
                    // notificationActivityResident(account.id);
                    var data = vm.multiCompanyData;
                    $rootScope.companyUser = data;
                    $localStorage.userId = CommonMethods.encryptIdUrl(data.id);
                    $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_USER");
                    $localStorage.userType = CommonMethods.encryptIdUrl(data.type);
                    if (data.identificationnumber != undefined && data.identificationnumber != null && data.identificationnumber != "") {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl(data.identificationnumber);
                    } else {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");
                    }
                    if (!$localStorage.houseSelected) {
                        $rootScope.houseSelected = data.houses[0];
                        $rootScope.filialNumber = data.houses[0].housenumber;
                        $localStorage.houseId = CommonMethods.encryptIdUrl(data.houses[0].id);
                        $localStorage.houseSelected = data.houses[0];
                    } else {
                        if (!$localStorage.houseSelected.roleResident) {
                            $rootScope.houseSelected = data.houses[0];
                            $rootScope.filialNumber = data.houses[0].housenumber;
                            $localStorage.houseId = CommonMethods.encryptIdUrl(data.houses[0].id);
                            $localStorage.houseSelected = data.houses[0];
                        } else {
                            $rootScope.houseSelected = $localStorage.houseSelected;
                            $rootScope.filialNumber = $localStorage.houseSelected.housenumber;
                            $localStorage.houseId = CommonMethods.encryptIdUrl($localStorage.houseSelected.id);
                        }
                    }
                    $rootScope.contextLiving = vm.contextLiving;
                    $rootScope.hideFilial = false;
                    $localStorage.companyId = CommonMethods.encryptIdUrl(data.companyId);
                    $rootScope.currentUserImage = data.image_url;
                    $rootScope.companyUser = data;
                    var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                    $rootScope.currency = companyConfig.currency;
                    $rootScope.secondCurrency = companyConfig.secondCurrency;
                    if (companyConfig == "admin") {
                        vm.hasContability = false;
                    } else {
                        if (companyConfig.hasContability == 1) {
                            vm.hasContability = true;
                        } else {
                            vm.hasContability = false;
                        }
                    }
                    $rootScope.companyConfigsLoaded = true;
                    var condo = condo;
                    vm.contextLiving = condo.name;
                    vm.company = condo;
                    $rootScope.company = condo;
                    $rootScope.contextLiving = vm.contextLiving;
                    if (condo.active == 0 || data.enabled == 0) {
                        logout();
                    }
                    break;
                case "ROLE_OWNER":
                    // notificationActivityResident(account.id);
                    var data = vm.multiCompanyData;
                    $rootScope.companyUser = data;
                    $rootScope.contextLiving = vm.contextLiving;
                    $rootScope.hideFilial = false;
                    $localStorage.companyId = CommonMethods.encryptIdUrl(data.companyId);
                    $rootScope.currentUserImage = data.image_url;
                    $rootScope.companyUser = data;
                    $localStorage.userType = CommonMethods.encryptIdUrl(data.type);
                    $localStorage.userId = CommonMethods.encryptIdUrl(data.id);
                    $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_OWNER");
                    if (data.identificationnumber != undefined && data.identificationnumber != null && data.identificationnumber != "") {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl(data.identificationnumber);
                    } else {
                        $localStorage.userIdNumber = CommonMethods.encryptIdUrl("");
                    }
                    if (!$localStorage.houseSelected) {
                        $rootScope.houseSelected = data.houses[0];
                        $rootScope.filialNumber = data.houses[0].housenumber;
                        $localStorage.houseId = CommonMethods.encryptIdUrl(data.houses[0].id);
                        $localStorage.houseSelected = data.houses[0];
                    } else {
                        if (!$localStorage.houseSelected.roleResident) {
                            $rootScope.houseSelected = data.houses[0];
                            $rootScope.filialNumber = data.houses[0].housenumber;
                            $localStorage.houseId = CommonMethods.encryptIdUrl(data.houses[0].id);
                            $localStorage.houseSelected = data.houses[0];
                        } else {
                            $rootScope.houseSelected = $localStorage.houseSelected;
                            $rootScope.filialNumber = $localStorage.houseSelected.housenumber;
                            $localStorage.houseId = CommonMethods.encryptIdUrl($localStorage.houseSelected.id);
                        }
                    }
                    var companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                    $rootScope.currency = companyConfig.currency;
                    $rootScope.secondCurrency = companyConfig.secondCurrency;
                    if (companyConfig == "admin") {
                        vm.hasContability = false;
                    } else {
                        if (companyConfig.hasContability == 1) {
                            vm.hasContability = true;
                        } else {
                            vm.hasContability = false;
                        }
                    }
                    $rootScope.companyConfigsLoaded = true;
                    var condo = vm.condo;
                    vm.contextLiving = condo.name;
                    $rootScope.contextLiving = vm.contextLiving;
                    vm.company = condo;
                    $rootScope.company = condo;
                    if (condo.active == 0 || data.enabled == 0) {
                        logout();
                    }
                    break;
                case "ROLE_RH":
                    var data = vm.multiCompanyData;
                    $rootScope.companyUser = data;
                    if (data != null) {
                        vm.contextLiving = " / " + data.enterprisename;
                        $rootScope.contextLiving = vm.contextLiving;
                        $rootScope.currentUserImage = null;
                    }
                    if (data.enable == 0) {
                        logout();
                    }
                    break;
                case "ROLE_JD":
                    $rootScope.companyUser = {};
                    $rootScope.companyUser.name = "Junta";
                    $rootScope.companyUser.lastname = "Directiva";
                    var data = vm.multiCompanyData;
                    $rootScope.companyUser = data;
                    $rootScope.showSelectCompany = false;
                    CommonMethods.setCompanyId(data.companyId);
                    $rootScope.companyId = data.companyId;
                    vm.backgroundSelectCompany = true;
                    $rootScope.currency = vm.companyConfig.currency;
                    $rootScope.secondCurrency = companyConfig.secondCurrency;
                    $localStorage.userRole = CommonMethods.encryptIdUrl("ROLE_JD");
                    var condo = vm.condo;
                    vm.contextLiving = condo.name;
                    $rootScope.companyName = condo.name;
                    $rootScope.contextLiving = vm.contextLiving;
                    $rootScope.currentUserImage = null;
                    $rootScope.companyUser.name = "Junta";
                    $rootScope.companyUser.lastname = "Directiva";
                    vm.company = condo;
                    $rootScope.company = condo;
                    vm.backgroundSelectCompany = true;
                    $rootScope.companyConfigsLoaded = true;
                    $state.go('announcement-user');
                    if (data.enabled == 0) {
                        logout();
                    }
                    break;
                }
            }
        };

        // Principal.identity().then(function (account) {
        //     if (account !== null) {
        // vm.getAcount();
        //     }
        // });

        // var subLogin = $scope.$on('authenticationSuccess', vm.getAcount);

        function login() {
            collapseNavbar();
            LoginService.open();
        }


        vm.openManual = function () {
            window.open('/#/manual-residente', '_blank');
        };

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }


        vm.selectCompany = function (company) {
            CommonMethods.setCompanyId(company.id);
            $localStorage.houseSelected = undefined;
            $localStorage.companyName = company.name;
            $localStorage.infoHouseNumber = undefined;
            $rootScope.company = company;
            // setTimeout(function () {
            vm.loadedMenu = false;
            // vm.getAcount();
            vm.loadCompanyConfig();
            $state.go("dashboard", {}, {reload: true});
            // })
        };
        vm.selectHouse = function (house) {
            $rootScope.unsubscribeVisitorAuthorizationRequest(globalCompany.getHouseId());
            $localStorage.houseSelected = house;
            $localStorage.infoHouseNumber = house.housenumber;
            $rootScope.houseSelected = house;
            $localStorage.houseId = CommonMethods.encryptIdUrl(house.id);
            $rootScope.companyUser.houseId = house.id;
            $rootScope.subscribeVisitorAuthorizationRequest();
            $state.go("announcement-user", {}, {reload: true});
        };

        vm.defineSelectCompanyColor = function (company) {
            if (company.id == globalCompany.getId()) {
                return vm.colorsMenu.secondButtonActive;
            } else {
                return vm.colorsMenu.secondButton;
            }
        };
        vm.defineSelectHouseColor = function (house) {
            if (house.id == globalCompany.getHouseId()) {
                return vm.colorsMenu.secondButtonActive;
            } else {
                return vm.colorsMenu.secondButton;
            }
        };
//        $scope.$on('$destroy', subChangeState);
//         $scope.$on('$destroy', subLogin);


        vm.findBootstrapEnvironment = function () {
            var envs = ['xs', 'sm', 'md', 'lg'];

            var $el = $('<div>');
            $el.appendTo($('body'));

            for (var i = envs.length - 1; i >= 0; i--) {
                var env = envs[i];

                $el.addClass('hidden-' + env);
                if ($el.is(':hidden')) {
                    $el.remove();
                    return env;
                }
            }
        };

        vm.quitarNoti = function () {
            $rootScope.activityResidentNoti = false;
        };

        vm.isScreenSizeSmall = function () {
            var envs = ['xs', 'sm', 'md'];
            var e = 0;
            for (var i = 0; i < envs.length; i++) {
                if (envs[i] === vm.findBootstrapEnvironment()) {
                    e++;
                }
            }
            if (e > 0) {
                return true;
            }
            return false;
        };
    }
})
();
