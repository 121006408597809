(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('AccountingFullPeriodCloseController', AccountingFullPeriodCloseController);

    AccountingFullPeriodCloseController.$inject = ['AccountingFullPeriod', 'globalCompany', '$state', 'CommonMethods', 'Modal', '$stateParams', '$uibModalInstance', '$scope'];

    function AccountingFullPeriodCloseController(AccountingFullPeriod, globalCompany, $state, CommonMethods, Modal, $stateParams, $uibModalInstance, $scope) {

        var vm = this;
        vm.clear = clear;
        vm.isReady = false;
        vm.newAccountingFullPeriod = {};
        var accountingFullPeriodId = CommonMethods.decryptIdUrl($stateParams.lastPeriodId);
        AccountingFullPeriod.get({id: accountingFullPeriodId}, function (data) {
            vm.accountingFullPeriod = data;
            vm.newAccountingFullPeriod.openDate = new Date(addMonths(data.closeDate, 1));
            vm.newAccountingFullPeriod.closeDate = new Date(addMonths(data.closeDate, 11));
            vm.autoConcept();
        });


        vm.autoConcept = function () {
            vm.newAccountingFullPeriod.name = "Periodo contable " + moment(vm.newAccountingFullPeriod.closeDate).format("YYYY");
        };

        vm.close = function () {
            Modal.confirmDialog("¿Está seguro que desea realizar el cierre contable?", "Por favor verifique TODA información antes de realizar el cierre", function () {
                Modal.confirmDialog("Confirmar realizar el cierre contable", "ESTA ACCIÓN NO SE PODRÁ REVERTIR", function () {
                    Modal.showLoadingBar();
                    vm.isLoading = true;
                    AccountingFullPeriod.closePeriod({
                        periodId: vm.accountingFullPeriod.id,
                        newPeriodName: vm.newAccountingFullPeriod.name,
                        newPeriodOpenDate: moment(vm.newAccountingFullPeriod.openDate).format(),
                        newPeriodCloseDate: moment(vm.newAccountingFullPeriod.closeDate).format(),
                    }, function () {
                        Modal.toast("Se realizó el cierre del periodo correctamente.");
                        $state.go('accounting-full-period', {}, { reload: 'accounting-full-period' });
                        Modal.hideLoadingBar();
                        vm.clear();
                    });
                });
            });
        };

        function addMonths(date, monthsToAdd) {
            var newDate = new Date(date);
            newDate.setMonth(newDate.getMonth() + monthsToAdd);
            if (monthsToAdd === 1) {
                newDate.setDate(1);
            } else {
                newDate.setDate(date.getDate());
            }
            return newDate;
        }


        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
