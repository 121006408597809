(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BillToPayHistoricController', BillToPayHistoricController);

    BillToPayHistoricController.$inject = ['BillToPayHistoric'];

    function BillToPayHistoricController(BillToPayHistoric) {

        var vm = this;

        vm.billToPayHistorics = [];

        loadAll();

        function loadAll() {
            BillToPayHistoric.query(function(result) {
                vm.billToPayHistorics = result;
                vm.searchQuery = null;
            });
        }
    }
})();
