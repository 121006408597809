(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('IndividualReleaseController', IndividualReleaseController);

    IndividualReleaseController.$inject = ['House', '$timeout', '$mdDialog', '$scope', 'Complaint', 'ParseLinks', 'AlertService', 'paginationConstants', '$rootScope', 'CommonMethods', '$state', 'globalCompany'];

    function IndividualReleaseController(House, $timeout, $mdDialog, $scope, Complaint, ParseLinks, AlertService, paginationConstants, $rootScope, CommonMethods, $state, globalCompany) {

        var vm = this;
        $rootScope.active = 'individual-release';
        vm.status = "-1";
        $rootScope.mainTitle = "Comun. individuales";
        vm.isReady = false;
        moment.locale("es");
        vm.complaints = [];
        vm.loadPage = loadPage;
        vm.loadAllByStatus = loadAllByStatus;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 0;
        vm.showFilterDiv = false;
        vm.isLoading = false;
        vm.filtering = false;

        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        vm.currentMonth = m;
        var firstDay = new Date(y, m, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.first_month_day = firstDay;
        vm.dates = {
            initial_time: firstDay,
            final_time: lastDay
        };

        vm.links = {
            last: 0
        };
        vm.clientId = -1;

        vm.searchTermFilial;
        vm.clearSearchTermFilial = function () {
            vm.searchTermFilial = '';
        };
        vm.typingSearchTermFilial = function (ev) {
            ev.stopPropagation();
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = true;
        // setTimeout(function () {
        loadAll();
        // }, 1000);
        vm.open = function (ev) {
            $mdDialog.show({
                templateUrl: 'app/entities/individual-release/individual-release-filter.html',
                scope: $scope,
                preserveScope: true,
                targetEvent: ev
            });
        };

        vm.close = function () {
            $mdDialog.hide();
        };
        vm.closeAndFilter = function () {
            vm.filtering = true;
            vm.changeStatus();
            $mdDialog.hide();
        };
        vm.changeStatus = function () {
            vm.page = 0;
            vm.complaints = [];
            vm.links = {
                last: 0
            };
            vm.isReady = false;
            vm.loadAllByStatus();
        };

        function loadAllHouses() {
            House.query({
                sort: sort(),
                companyId: globalCompany.getId()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.houses = data;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAllHouses();

        function loadAllByStatus() {
            if (vm.filtering) {
                Complaint.queryByStatus({
                    companyId: globalCompany.getId(),
                    status: parseInt(vm.status),
                    page: vm.page,
                    category: 3,
                    size: 20,
                    clientId: vm.clientId,
                    initTime: moment(vm.dates.initial_time).format(),
                    finalTime: moment(vm.dates.final_time).format(),
                    sort: sort()
                }, onSuccess, onError);
            } else {
                loadAll();
            }

            function sort() {
                var result = [];
                if (vm.predicate !== 'creationDate') {
                    result.push('creationDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    data[i].showingCreationDate = moment(data[i].creationDate).format("DD MMM");
                    vm.complaints.push(data[i]);
                }
                vm.isLoading = false;
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadAll() {
            Complaint.query({
                companyId: globalCompany.getId(),
                page: vm.page,
                category: 3,
                size: 20,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [];
                if (vm.predicate !== 'creationDate') {
                    result.push('creationDate,desc');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    data[i].showingCreationDate = moment(data[i].creationDate).format("DD MMM");
                    vm.complaints.push(data[i]);
                }
                vm.isReady = true;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset() {
            vm.page = 0;
            vm.complaints = [];
            loadAll();
        }

        vm.viewDetail = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('individual-release-detail', {
                id: encryptedId
            });
        };

        function loadPage(page) {
            if (vm.isLoading) return; // Si ya está cargando, salir
            vm.isLoading = true;
            vm.page = page;
            $timeout(function () {
                if (vm.filtering) {
                    loadAllByStatus();
                } else {
                    loadAll();
                }
            }, 1000); //
        }
    }
})();
