(function() {
    'use strict';

    angular
        .module('aditumApp')
        .controller('BillToPayHistoricClientDetailController', BillToPayHistoricClientDetailController);

    BillToPayHistoricClientDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'BillToPayHistoricClient', 'Company', 'AccountingAccount', 'BillToPayHistoric'];

    function BillToPayHistoricClientDetailController($scope, $rootScope, $stateParams, previousState, entity, BillToPayHistoricClient, Company, AccountingAccount, BillToPayHistoric) {
        var vm = this;

        vm.billToPayHistoricClient = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('aditumApp:billToPayHistoricClientUpdate', function(event, result) {
            vm.billToPayHistoricClient = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
