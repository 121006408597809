(function () {
    'use strict';

    angular
        .module('aditumApp')
        .controller('DefineBankGtiSinpeMovil', DefineBankGtiSinpeMovil);

    DefineBankGtiSinpeMovil.$inject = ['$uibModalInstance', '$localStorage', '$rootScope', '$scope', 'globalCompany', 'Modal','$translate'];

    function DefineBankGtiSinpeMovil($uibModalInstance, $localStorage, $rootScope, $scope, globalCompany, Modal,translate) {
        var vm = this;
        vm.clear = clear;

        vm.bank = {};
        vm.bankOptions = [
            {
                CodBanco: 102,
                Nombre: "BAC",
                Telefono: 70701222
            },
            {
                CodBanco: 104,
                Nombre: "DAVIVIENDA",
                Telefono: 70707474
            },
            {
                CodBanco: 114,
                Nombre: "LAFISE",
                Telefono: 9091
            },
            {
                CodBanco: 116,
                Nombre: "PROMERICA",
                Telefono: 62232450
            },
            {
                CodBanco: 117,
                Nombre: "BANCO BCT",
                Telefono: 60400300
            },
            {
                CodBanco: 118,
                Nombre: "COOCIQUE",
                Telefono: 62229537
            },
            {
                CodBanco: 119,
                Nombre: "COOPECAJA",
                Telefono: 62229526
            },
            {
                CodBanco: 120,
                Nombre: "COOPENAE",
                Telefono: 62232599
            },
            {
                CodBanco: 121,
                Nombre: "CREDECOOP",
                Telefono: 71984256
            },
            {
                CodBanco: 122,
                Nombre: "COOPELECHEROS",
                Telefono: 60405957
            },
            {
                CodBanco: 151,
                Nombre: "BNCR",
                Telefono: 2627
            },
            {
                CodBanco: 152,
                Nombre: "BCR",
                Telefono: 4066
            },
            {
                CodBanco: 803,
                Nombre: "GRUPO MUTUAL ALAJUELA LA VIVIENDA",
                Telefono: 70707079
            },
            {
                CodBanco: 804,
                Nombre: "MUCAP",
                Telefono: 62229525
            },
            {
                CodBanco: 813,
                Nombre: "COOPEALIANZA",
                Telefono: 62229523
            },
            {
                CodBanco: 821,
                Nombre: "CAJA ANDE",
                Telefono: 62229532
            }
        ];

        vm.save = function(){
            var title = translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.CONFIRM_BANK');
            var content = translate.instant('aditumApp.financesResidentAccount.subsidiaryDebts.CONFIRM_BANK_TEXT');
            Modal.confirmDialog(title,content,function(){
                $localStorage.bankGTIPayment = vm.bank;
                $uibModalInstance.close({});
            });
        };

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
