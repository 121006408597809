(function () {
    'use strict';

    angular
        .module('aditumApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('generatePayment', {
                parent: 'entity',
                url: '/generate-payment',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment/payment-generate.html',
                        // templateUrl: 'app/entities/company/commingSoonFinanzes.html',
                        controller: 'GeneratePaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('house');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('otherPayment', {
                parent: 'entity',
                url: '/otro-pago',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment/other-payment.html',
                        // templateUrl: 'app/entities/company/commingSoonFinanzes.html',

                        controller: 'OtherPaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('house');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('advancePayment', {
                parent: 'entity',
                url: '/adelanto-condomino',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT'],
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment/payment-in-advance.html',
                        // templateUrl: 'app/entities/company/commingSoonFinanzes.html',

                        controller: 'AdvancePaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('house');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment-report', {
                parent: 'entity',
                url: '/reporte-ingresos?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT', 'ROLE_JD'],
                    pageTitle: 'Aditum'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment/payment-report.html',
                        // templateUrl: 'app/entities/company/commingSoonFinanzes.html',
                        controller: 'PaymentReportController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payment');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment', {
                parent: 'entity',
                url: '/payment?page&sort&search',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT'],
                    pageTitle: 'aditumApp.payment.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment/payments.html',
                        //  templateUrl: 'app/entities/company/commingSoonFinanzes.html',

                        controller: 'PaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('payment');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('payment-detail', {
                parent: 'entity',
                url: '/payment/:id/detail',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT', 'ROLE_USER', 'ROLE_OWNER', 'ROLE_JD'],
                    pageTitle: 'Aditum'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment/payment-detail.html',
                        //  templateUrl: 'app/entities/company/commingSoonFinanzes.html',

                        controller: 'PaymentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Payment', 'CommonMethods', function ($stateParams, Payment, CommonMethods) {
                        var id = CommonMethods.decryptIdUrl($stateParams.id);
                        return Payment.getOneComplete({
                            id: id
                        }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', '$rootScope', function ($translate, $translatePartialLoader, $rootScope) {
                        $translatePartialLoader.addPart('financesResidentAccount');
                        return $translate.refresh().then(function () {
                            var laTranslationKeys = [
                                "aditumApp.financesResidentAccount.accountStatus.generalBalances",
                            ];

                            $translate(laTranslationKeys).then(function (translations) {
                                for (var prop in translations) {
                                    if (Object.prototype.hasOwnProperty.call(translations, prop)) {
                                        $rootScope.translations[prop] = translations[prop];
                                    }
                                }
                            });
                        });
                    }]
                }
            })
            .state('payment-edit', {
                parent: 'entity',
                url: '/payment/:id/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT', 'ROLE_USER', 'ROLE_OWNER', 'ROLE_JD'],
                    pageTitle: 'Aditum'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/payment/payment-dialog.html',
                        controller: 'PaymentDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Payment', 'CommonMethods', function ($stateParams, Payment, CommonMethods) {
                        var id = CommonMethods.decryptIdUrl($stateParams.id);
                        return Payment.getOneComplete({
                            id: id
                        }).$promise;
                    }],
                }
            })
            .state('payment.new', {
                parent: 'payment',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER','ROLE_ACCOUNTANT']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/payment/payment-dialog.html',
                    // templateUrl: 'app/entities/company/commingSoonFinanzes.html',

                        controller: 'PaymentDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    date: null,
                                    receiptNumber: null,
                                    transaction: null,
                                    account: null,
                                    paymentMethod: null,
                                    comments: null,
                                    ammount: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('payment', null, {reload: 'payment'});
                    }, function () {
                        $state.go('payment');
                    });
                }]
            });
    }

})();
