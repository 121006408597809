(function () {
    'use strict';

    angular
            .module('aditumApp')
            .controller('EgressToPayReportController', EgressToPayReportController);

    EgressToPayReportController.$inject = ['AdministrationConfiguration', 'Modal', '$scope', '$state', 'Egress', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'CommonMethods', 'Proveedor', '$rootScope', 'globalCompany'];

    function EgressToPayReportController(AdministrationConfiguration, Modal, $scope, $state, Egress, ParseLinks, AlertService, paginationConstants, pagingParams, CommonMethods, Proveedor, $rootScope, globalCompany) {
        $rootScope.active = "cuentasPorPagar";
        var vm = this;
        $rootScope.mainTitle = "Reporte cuentas por pagar";
        vm.isReady = true;
        vm.isReady2 = false;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.exportingExcel = false;
//        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.propertyName = 'id';
        vm.reverse = true;
        vm.showFilterDiv = true;
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, m + 1, 0);
        vm.showNoResults = false;
        vm.hideReportForm = false;
        vm.loadingReport = false;

        vm.dates = {
            initial_time: firstDay, final_time: lastDay
        };

        vm.changeFormat = function () {
            vm.format = 'MMMM';
            vm.hideDate = false;
        };


        vm.format = 'L';
        moment.locale("es");

        vm.locale = {
            formatDate: function (date) {
                var m = moment(date);
                return m.isValid() ? m.format(vm.format) : '';
            }
        };

        vm.createMonths = function () {
            vm.isReady = true;
            vm.dates.initial_time = new Date(vm.dates.initial_time.getFullYear(), vm.dates.initial_time.getMonth(), 1);
            vm.dates.final_time = new Date(vm.dates.final_time.getFullYear(), vm.dates.final_time.getMonth() + 1, 0);
            vm.fileInitialDate = moment(vm.dates.initial_time).format();
            vm.fileFinalDate = moment(vm.dates.final_time).format();
            vm.changeFormat();
        };

        vm.page = 0;
        vm.links = {
            last: 0
        };

        vm.tableToExcel = function (table) {
            vm.exportingExcel = true;
            setTimeout(function () {
                var uri = 'data:application/vnd.ms-excel;base64,',
                    template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
                    base64 = function (s) {
                        return window.btoa(unescape(encodeURIComponent(s)));
                    }, format = function (s, c) {
                        return s.replace(/{(\w+)}/g, function (m, p) {
                            return c[p];
                        });
                    };
                var workSheetName = $rootScope.companyName + " - REPORTE DE CUENTAS POR PAGAR - del " + moment(vm.initial_time).format("L") + " al " + moment(vm.final_time).format("L");
                if (!table.nodeType) table = document.getElementById(table);
                var ctx = {worksheet: workSheetName || 'Worksheet', table: table.innerHTML};
                var a = document.createElement('a');
                a.href = uri + base64(format(template, ctx));
                a.download = workSheetName + '.xls';
                    //triggering the function
                a.click();
                vm.exportingExcel = false;
            }, 1);
        };
        vm.isDisableButton = function () {
            return vm.dates.initial_time == undefined || vm.dates.final_time == undefined;
        };


        vm.generateReport = function () {
            vm.egresses = [];
            vm.isReady2 = false;
            vm.loadingReport = true;
            Egress.findUntilDatesByCompany({
                final_time: moment(vm.dates.final_time).format(),
                companyId: globalCompany.getId(),
                page: vm.page,
                size: 2000,
            }, onSuccess, onError);

            function onSuccess(data, headers) {
                vm.report = data;
                vm.egresses = vm.report.egresses;
                vm.companyConfig = CommonMethods.getCurrentCompanyConfig(globalCompany.getId());
                formatEgresos(vm.egresses);
                vm.isReady2 = true;
                vm.hideReportForm = true;
                vm.loadingReport = false;
                vm.showNoResults = vm.egresses.length === 0;
            }

            function onError(error) {
                Modal.toast("Un error inesperado sucedió");
                AlertService.error(error.data.message);
            }

        };


        function formatEgresos(egresses) {
            angular.forEach(egresses, function (value, key) {
                if (value.paymentDate == null || value.paymentDate == 'undefined') {
                    value.paymentDate = "No pagado";
                }
                if (value.folio == null || value.folio == 'undefined') {
                    value.folio = 'Sin Registrar';
                }
                if (value.reference == null || value.reference == 'undefined') {
                    value.reference = 'Sin Registrar';
                }
                if (value.currency == vm.companyConfig.currency) {
                    value.showOriginalCurrency = true;
                } else {
                    if (value.ammountDoubleMoney == null) {
                        value.showOriginalCurrency = true;
                    } else {
                        value.showOriginalCurrency = false;
                    }
                }
                var intValue = parseInt(value.proveedor, 10);
                console.log(intValue);// Convierte a número base 10
                if (!isNaN(intValue)) {
                    angular.forEach(vm.proveedores, function (proveedor, key) {
                        if (proveedor.id == value.proveedor) {
                            value.proveedor = proveedor.empresa;
                        }
                    });
                }
            });
            vm.isReady = true;
            vm.isReady2 = true;
        }


        vm.clickPopover = function (id) {
            $('[data-toggle="' + id + '"]').popover({
                placement: 'bottom',
                html: true,
                animation: true,
                trigger: "focus",
                content: '<div><a href="#"></a><div ><h4 >Reportar el pago de este egreso</h4> <h1 class="text-center"><button type=button" class="btn btn-primary" onclick="formats()" >Reportar pago</button></h1></div>'
            });
            $(document).on("click", ".popoversd", function () {
            });
        };

        vm.detailEgress = function (id, pay) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('egress-detail', {
                id: encryptedId, pay: pay
            });
        };

        vm.editEgress = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('egress-tabs.edit', {
                id: encryptedId
            });
        };

        vm.payEgress = function (id) {
            var encryptedId = CommonMethods.encryptIdUrl(id);
            $state.go('egress.edit', {
                id: encryptedId
            });
        };


        vm.stopConsulting = function () {
            vm.isReady2 = true;
            var firstDay = CommonMethods.setTimezone(new Date(y, m, 1));
            var lastDay = CommonMethods.setTimezone(new Date(y, m + 1, 0));
            vm.dates.initial_time = firstDay;
            vm.dates.final_time = lastDay;
            pagingParams.page = 1;
            pagingParams.search = null;
            vm.isConsulting = false;
            vm.generateReport();
            vm.titleConsult = "";
        };

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        vm.formatearNumero = function (nStr) {

            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? ',' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
        };

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.isConsulting == true ? moment(vm.dates.initial_time).format('l') + " " + moment(vm.dates.final_time).format('l') : null,
            });
        }

        function loadProveedors() {
            Proveedor.query({companyId: globalCompany.getId()}).$promise.then(onSuccessProveedores);

            function onSuccessProveedores(data, headers) {
                vm.proveedores = data;
                vm.createMonths();
            }
        }

        loadProveedors();

    }
}

)();
